import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';

/**
 * Setup __Тип (наименование)__.
 *
 * @returns {React.ReactElement}
 */
export const useSetupArrangeElementTypeIdField = () => {
  const { editMode } = useMode();

  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();
  const { data = [] } = useGetDictionary('arrangeElementType');
  const { typeId: parentTypeId } = useGetParentCard();

  const options = data.map((item) => ({ ...item.attribute, id: item.id }));

  /**
   * Фильтр.
   *
   * @param {Array} data - Данные.
   * @returns {Array} - Возвращает данные фильтрованные.
   */
  const filter = (data) =>
    data.filter(({ parent_ogh_object_type_list = [] }) => {
      return parent_ogh_object_type_list?.includes(parentTypeId);
    });

  const disabled = !(editMode && isEditCurrentObjectIsExternalSystem);

  return { disabled, filter, options };
};
