import { useGetFilesForSignature } from 'app/api/hooks/signs/useGetFilesForSignature/useGetFilesForSignature';
import { SAVE } from 'app/api/requests/signs/signOghSave';
import { getCurrentDate } from 'app/utils/date/getCurrentDate';
import { getDateMinusDays } from 'app/utils/date/getDateMinusDays';
import { getNowDate } from 'app/utils/date/getNowDate';
import { getTomorrowDate } from 'app/utils/date/getTomorrowDate';
import { ContinueFFDialog } from 'core/form/finalForm/components/dialogs/ContinueFF.Dialog';
import { SIGNATURES_NAME } from 'core/form/finalForm/fields/prepared/FileSignatureCryptoProFF';
import { RANDOM_BOOL, RANDOM_ID, RANDOM_WORD } from 'core/forTesting/constants';
import { Dialog } from 'core/uiKit/components/dialogs/Dialog';
import { LoaderCreateFiles } from 'core/uiKit/components/loaders/LoaderCreateFiles';
import { toast } from 'core/uiKit/components/Toast';
import { SEND_REASON_NAME } from 'core/uiKit/preparedInputs/selects/multiSelect/SendReason';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import { shallow } from 'enzyme';
import { useState } from 'react';
import { vi } from 'vitest';

import {
  DialogApproveElectronicSignature,
  formValidation,
} from './Dialog.ApproveElectronicSignature';
import { DialogIntersectionsApproveElectronicSignature } from './Dialog.Intersections.ApproveElectronicSignature';
import { FormApproveElectronicSignature } from './Form.ApproveElectronicSignature';
import { useSignOghSaveDialogApproveElectronicSignature } from './useSignOghSave.DialogApproveElectronicSignature';

vi.mock('core/form/finalForm/utils/validate');
vi.mock('app/utils/date/getNowDate');
vi.mock('core/utils/hooks/useGetObjectId');
vi.mock('app/api/hooks/signs/useSignOghSave');
vi.mock('./useSignOghSave.DialogApproveElectronicSignature');
vi.mock('app/api/hooks/signs/useGetFilesForSignature/useGetFilesForSignature');
vi.mock('react', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    useState: vi.fn(),
  };
});

describe('file DialogApproveElectronicSignature', () => {
  it('simple test text', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetObjectId.mockReturnValue({ objectId: 1 });
    useGetFilesForSignature.mockReturnValue({});
    useSignOghSaveDialogApproveElectronicSignature.mockReturnValue({});
    useState.mockReturnValue([1, 2]);

    // Act
    const wrapper = shallow(<DialogApproveElectronicSignature />);

    // Assert
    expect(wrapper.text()).toBe(
      '<DialogIntersectionsApproveElectronicSignature /><Dialog /><ContinueFFDialog />',
    );
  });

  it('useSignOghSave call with', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const MOCK_OBJECT_ID = RANDOM_ID;
    const mockOnClose = vi.fn();
    useGetObjectId.mockReturnValue({
      objectId: MOCK_OBJECT_ID,
    });

    useGetFilesForSignature.mockReturnValue({
      data: [RANDOM_ID],
    });
    useSignOghSaveDialogApproveElectronicSignature.mockReturnValue({});
    useState.mockReturnValue([1, 2]);

    // Act
    shallow(<DialogApproveElectronicSignature onClose={mockOnClose} />);

    // Assert
    expect(useSignOghSaveDialogApproveElectronicSignature).toHaveBeenCalledWith(
      {
        objectCardFiles: [RANDOM_ID],
        objectId: MOCK_OBJECT_ID,
        onErrorCallback: mockOnClose,
        onSuccessCallback: mockOnClose,
        signType: SAVE,
      },
    );
  });

  describe('is files in data useGetFilesForSignature', () => {
    it('нет файлов нет пересечения', () => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      useGetObjectId.mockReturnValue({
        objectId: RANDOM_ID,
      });
      useGetFilesForSignature.mockReturnValue({
        data: [1],
      });
      useSignOghSaveDialogApproveElectronicSignature.mockReturnValue({});
      useState.mockReturnValue([true, 3]);

      // Act
      const wrapper = shallow(<DialogApproveElectronicSignature />);

      // Assert
      expect(wrapper.find(Dialog).props()).toMatchObject({
        isOpen: true,
      });
      expect(wrapper.find(ContinueFFDialog).props()).toMatchObject({
        isOpen: false,
      });
    });

    it('нет файлов, есть пересечения', () => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      useGetObjectId.mockReturnValue({
        objectId: RANDOM_ID,
      });
      useGetFilesForSignature.mockReturnValue({
        data: [1],
      });
      useSignOghSaveDialogApproveElectronicSignature.mockReturnValue({});
      useState.mockReturnValue([false, 3]);

      // Act
      const wrapper = shallow(<DialogApproveElectronicSignature />);

      // Assert
      expect(wrapper.find(Dialog).props()).toMatchObject({
        isOpen: false,
      });
      expect(wrapper.find(ContinueFFDialog).props()).toMatchObject({
        isOpen: false,
      });
    });

    it('есть файлы есть пересечения', () => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      const MOCK_OBJECT_ID = RANDOM_ID;
      useGetObjectId.mockReturnValue({
        objectId: MOCK_OBJECT_ID,
      });
      useGetFilesForSignature.mockReturnValue({
        data: [{ file: RANDOM_ID }],
      });
      useSignOghSaveDialogApproveElectronicSignature.mockReturnValue({});
      useState.mockReturnValue([false, 2]);

      // Act
      const wrapper = shallow(<DialogApproveElectronicSignature />);

      // Assert
      expect(wrapper.find(Dialog).props()).toMatchObject({
        isOpen: false,
      });
      expect(wrapper.find(ContinueFFDialog).props()).toMatchObject({
        isOpen: false,
      });
    });

    it('есть файлы нет пересечения', () => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      const MOCK_OBJECT_ID = RANDOM_ID;
      useGetObjectId.mockReturnValue({
        objectId: MOCK_OBJECT_ID,
      });
      useGetFilesForSignature.mockReturnValue({
        data: [{ file: RANDOM_ID }],
      });
      useSignOghSaveDialogApproveElectronicSignature.mockReturnValue({});
      useState.mockReturnValue([true, 2]);

      // Act
      const wrapper = shallow(<DialogApproveElectronicSignature />);

      // Assert
      expect(wrapper.find(Dialog).props()).toMatchObject({
        isOpen: false,
      });
      expect(wrapper.find(ContinueFFDialog).props()).toMatchObject({
        isOpen: true,
      });
    });
  });

  it('props Dialog', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetObjectId.mockReturnValue({});
    useGetFilesForSignature.mockReturnValue({});
    useSignOghSaveDialogApproveElectronicSignature.mockReturnValue({});
    useState.mockReturnValue([1, 2]);

    // Act
    const wrapper = shallow(<DialogApproveElectronicSignature />);

    // Assert
    expect(wrapper.find(Dialog).props()).toMatchObject({
      Body: <LoaderCreateFiles />,
      maxWidth: 'xs',
      textHeader: 'Формируются файлы для подписания',
    });
  });
  it('props ContinueFFDialog', () => {
    expect.hasAssertions();

    // Arrange (всякие моки)
    const mockOnClose = vi.fn();
    const mockOnSubmit = vi.fn();
    useGetObjectId.mockReturnValue({});
    useGetFilesForSignature.mockReturnValue({});
    useSignOghSaveDialogApproveElectronicSignature.mockReturnValue({
      isLoadingSignOghSave: RANDOM_BOOL,
      sendSign: mockOnSubmit,
    });
    useState.mockReturnValue([1, 2]);

    // Act
    const wrapper = shallow(
      <DialogApproveElectronicSignature onClose={mockOnClose} />,
    );

    // Assert
    expect(wrapper.find(ContinueFFDialog).props()).toMatchObject({
      Body: <FormApproveElectronicSignature />,
      closeDialog: mockOnClose,
      isLoading: RANDOM_BOOL,
      onSubmit: expect.any(Function),
      textHeader: 'Форма подписания файлов',
      validate: formValidation,
    });
  });

  it('useGetFilesForSignature was called with', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    const spyErrorToast = vi.spyOn(toast, 'error');
    useGetFilesForSignature.mockImplementation(
      (id, save, { onErrorCallback }) => {
        onErrorCallback({ message: RANDOM_WORD });
        return {
          data: undefined,
        };
      },
    );
    useSignOghSaveDialogApproveElectronicSignature.mockReturnValue({
      data: [RANDOM_ID],
    });
    const onCloseSpy = vi.fn();
    useState
      .mockReturnValueOnce(['dd', 'ff'])
      .mockReturnValueOnce([RANDOM_BOOL, 3]);

    // Act
    shallow(<DialogApproveElectronicSignature onClose={onCloseSpy} />);

    // Assert
    expect(useGetFilesForSignature).toHaveBeenCalledWith(RANDOM_ID, SAVE, {
      enabled: RANDOM_BOOL,
      onErrorCallback: expect.any(Function),
    });
    expect(spyErrorToast).toBeCalledWith(RANDOM_WORD);
  });

  describe('DialogIntersectionsApproveElectronicSignaturec', () => {
    it('useState вызывается по порядку с нужными параметрами', () => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      useGetObjectId.mockReturnValue({
        objectId: RANDOM_ID,
      });
      useGetFilesForSignature.mockReturnValue({
        data: [RANDOM_ID],
      });
      useSignOghSaveDialogApproveElectronicSignature.mockReturnValue({
        data: [RANDOM_ID],
      });
      const onCloseSpy = vi.fn();
      useState.mockReturnValue([RANDOM_BOOL, 2]);

      // Act
      shallow(<DialogApproveElectronicSignature onClose={onCloseSpy} />);

      // Assert
      expect(useState).toHaveBeenNthCalledWith(1, true);
      expect(useState).toHaveBeenNthCalledWith(2, false);
    });
    it('props', () => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      useGetObjectId.mockReturnValue({
        objectId: RANDOM_ID,
      });
      useGetFilesForSignature.mockReturnValue({
        data: [RANDOM_ID],
      });
      useSignOghSaveDialogApproveElectronicSignature.mockReturnValue({
        data: [RANDOM_ID],
      });
      const onCloseSpy = vi.fn();
      useState
        .mockReturnValueOnce([!RANDOM_BOOL, 'ff'])
        .mockReturnValueOnce([RANDOM_BOOL, 3]);

      // Act
      const wrapper = shallow(
        <DialogApproveElectronicSignature onClose={onCloseSpy} />,
      );

      // Assert
      expect(
        wrapper.find(DialogIntersectionsApproveElectronicSignature).props(),
      ).toMatchObject({
        isOpen: !RANDOM_BOOL,
        onCloseDialogIntersections: expect.any(Function),
        onContinueDialogIntersections: expect.any(Function),
      });
    });

    it('onContinueDialogIntersections', () => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      useGetObjectId.mockReturnValue({
        objectId: RANDOM_ID,
      });
      useGetFilesForSignature.mockReturnValue({
        data: [RANDOM_ID],
      });
      useSignOghSaveDialogApproveElectronicSignature.mockReturnValue({
        data: [RANDOM_ID],
      });
      const onCloseSpy = vi.fn();
      const setIntersectionsOpenSpy = vi.fn();
      const setIntersectionsSpy = vi.fn();
      useState
        .mockReturnValueOnce([!RANDOM_BOOL, setIntersectionsOpenSpy])
        .mockReturnValueOnce([RANDOM_BOOL, setIntersectionsSpy]);

      // Act
      const wrapper = shallow(
        <DialogApproveElectronicSignature onClose={onCloseSpy} />,
      );
      wrapper
        .find(DialogIntersectionsApproveElectronicSignature)
        .prop('onContinueDialogIntersections')();

      // Assert
      expect(setIntersectionsOpenSpy).toHaveBeenCalledWith(false);
      expect(setIntersectionsSpy).toHaveBeenCalledWith(true);
    });

    it('onCloseDialogIntersections', () => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      useGetObjectId.mockReturnValue({
        objectId: RANDOM_ID,
      });
      useGetFilesForSignature.mockReturnValue({
        data: [RANDOM_ID],
      });
      useSignOghSaveDialogApproveElectronicSignature.mockReturnValue({
        data: [RANDOM_ID],
      });
      const onCloseSpy = vi.fn();
      const setIntersectionsOpenSpy = vi.fn();
      const setIntersectionsSpy = vi.fn();
      useState
        .mockReturnValueOnce([!RANDOM_BOOL, setIntersectionsOpenSpy])
        .mockReturnValueOnce([RANDOM_BOOL, setIntersectionsSpy]);

      // Act
      const wrapper = shallow(
        <DialogApproveElectronicSignature onClose={onCloseSpy} />,
      );
      wrapper
        .find(DialogIntersectionsApproveElectronicSignature)
        .prop('onCloseDialogIntersections')();

      // Assert
      expect(setIntersectionsOpenSpy).toHaveBeenCalledWith(false);
      expect(onCloseSpy).toHaveBeenCalled();
    });
  });
});

describe('validate test', function () {
  it('test startDate < yesterday date', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    vi.useFakeTimers();
    vi.setSystemTime(new Date('10 Jan 2000 12:12:00 GMT').getTime());

    const values = {
      [SEND_REASON_NAME]: RANDOM_WORD,
      [SIGNATURES_NAME]: RANDOM_WORD,
      startDate: getDateMinusDays(5),
    };
    getNowDate.mockReturnValue(RANDOM_WORD);

    // Act

    // Assert
    expect(formValidation(values)).toStrictEqual({
      startDate:
        'Дата начала действия не может быть раньше 10.01.2000. Укажите корректное значение',
    });
  });

  it('test startDate > yesterday date', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    vi.useFakeTimers();
    vi.setSystemTime(new Date('10 Jan 2000 12:12:00 GMT').getTime());

    const values = {
      [SEND_REASON_NAME]: RANDOM_WORD,
      [SIGNATURES_NAME]: RANDOM_WORD,
      startDate: getTomorrowDate(),
    };
    getNowDate.mockReturnValue(RANDOM_WORD);

    // Act

    // Assert
    expect(formValidation(values)).toStrictEqual({
      documentFile: 'обязательное поле',
    });
  });

  it('test startDate today', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const values = {
      [SEND_REASON_NAME]: RANDOM_WORD,
      [SIGNATURES_NAME]: RANDOM_WORD,
      startDate: getCurrentDate(),
    };
    getNowDate.mockReturnValue(RANDOM_WORD);

    // Act

    // Assert
    expect(formValidation(values)).toStrictEqual(null);
  });

  it('test required fields', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const values = {};
    getNowDate.mockReturnValue(RANDOM_WORD);

    // Act

    // Assert
    expect(formValidation(values)).toStrictEqual({
      [SEND_REASON_NAME]: 'обязательное поле',
      [SIGNATURES_NAME]: 'Выберите сертификат для подписи и нажмите подписать',
    });
  });
});
