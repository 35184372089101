import { ActOfCompletedWork } from 'app/components/card/ogh/ImprovementTerritoryCard/components/ActOfCompletedWork/ActOfCompletedWork';
import { useActOfCompletedElectronicSignatureFlag } from 'app/pages/cardsOgh/components/tables/Documents/FieldsRF/preparedFields/ActOfCompletedWork.ElectronicSignature/hooks/useActOfCompletedElectronicSignatureFlag';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

vi.mock(
  'app/pages/cardsOgh/components/tables/Documents/FieldsRF/preparedFields/ActOfCompletedWork.ElectronicSignature/hooks/useActOfCompletedElectronicSignatureFlag',
);

describe('ActOfCompletedWorkSpec', () => {
  it('case true', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useActOfCompletedElectronicSignatureFlag.mockReturnValue(true);

    // 🔥 Act

    const wrapper = shallow(<ActOfCompletedWork />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<ActOfCompletedWorkElectronicSignature />');
  });
  it('case false', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useActOfCompletedElectronicSignatureFlag.mockReturnValue(false);

    // 🔥 Act

    const wrapper = shallow(<ActOfCompletedWork />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<ActOfCompletedWorkRF />');
  });
});
