import { roadwayAreaValidation } from './roadwayArea.validation';

const error1 = {
  roadway_area:
    'значение параметра «Проезжая часть всего, кв.м» должно быть не больше значения параметра «Общая площадь в ТС, кв.м»',
};

const error2 = {
  roadway_area:
    'значение параметра «Проезжая часть всего, кв.м» должно быть не больше значения параметра «Общая площадь в ТС, кв.м» и не больше суммы значений параметров «Площадь ручной уборки проезжей части (без парковочного пространства), кв.м», «Площадь механизированной уборки проезжей части (без парковочного пространства), кв.м», «Площадь ручной уборки парковочного пространства, кв.м» и «Площадь механизированной уборки парковочного пространства, кв.м»',
};

describe('title roadwayArea.validation.spec', () => {
  it.each`
    roadwayArea  | roadwayNoprkgManualCleanArea | roadwayNoprkgAutoCleanArea | roadwayPrkgManualCleanArea | roadwayPrkgAutoCleanArea | expected     | description
    ${41.7776}   | ${10.4449}                   | ${10.4449}                 | ${10.4441}                 | ${10.4441}               | ${undefined} | ${'В сумме 3 знака после запятой'}
    ${41.7776}   | ${10.4444}                   | ${10.4444}                 | ${10.4444}                 | ${10.4444}               | ${undefined} | ${'4 знака и в сумме и roadwayArea'}
    ${40.7776}   | ${10.4444}                   | ${10.4444}                 | ${10.4444}                 | ${10.4444}               | ${error2}    | ${'сумма больше roadwayArea'}
    ${'40.7776'} | ${'10.4444'}                 | ${'10.4444'}               | ${'10.4444'}               | ${'10.4444'}             | ${error2}    | ${'сумма больше roadwayArea, значения пришли строкой'}
    ${undefined} | ${10.4444}                   | ${10.4444}                 | ${10.4444}                 | ${10.4444}               | ${undefined} | ${'roadwayArea undefined'}
    ${undefined} | ${undefined}                 | ${undefined}               | ${undefined}               | ${undefined}             | ${undefined} | ${'roadwayArea undefined'}
  `(
    'test case значение параметра «Проезжая часть всего, кв.м» должно быть не больше...',
    ({
      roadwayArea,
      roadwayNoprkgManualCleanArea,
      roadwayNoprkgAutoCleanArea,
      roadwayPrkgManualCleanArea,
      roadwayPrkgAutoCleanArea,
      expected,
    }) => {
      expect.hasAssertions();
      // Arrange (всякие моки)

      const obj = {
        roadwayArea,
        roadwayNoprkgAutoCleanArea,
        roadwayNoprkgManualCleanArea,
        roadwayPrkgAutoCleanArea,
        roadwayPrkgManualCleanArea,
      };

      // Act

      // Assert
      expect(roadwayAreaValidation(obj, false)).toStrictEqual(expected);
    },
  );
  it.each`
    roadwayArea | totalArea | isOrphanObject | expected
    ${11}       | ${10}     | ${true}        | ${error1}
    ${10}       | ${10}     | ${true}        | ${undefined}
    ${10}       | ${10}     | ${false}       | ${undefined}
  `(
    'test case значение параметра «Проезжая часть всего, кв.м» должно быть не больше значения параметра «Общая площадь в ТС, кв.м»',
    ({ roadwayArea, totalArea, isOrphanObject, expected }) => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      const obj = {
        roadwayArea,
        totalArea,
      };

      // Act

      // Assert
      expect(roadwayAreaValidation(obj, isOrphanObject)).toStrictEqual(
        expected,
      );
    },
  );

  it('проверка на округление', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const roadway_prkg_auto_clean_area = 0;
    const roadway_noprkg_auto_clean_area = 10934.6558;
    const roadway_prkg_manual_clean_area = 2135.1381;
    const roadway_noprkg_manual_clean_area = 180.1138;
    const roadway_area = 13249.9077;

    // Act
    const formFalues = {
      roadway_area,
      roadway_noprkg_auto_clean_area,
      roadway_noprkg_manual_clean_area,
      roadway_prkg_auto_clean_area,
      roadway_prkg_manual_clean_area,
    };

    // Assert
    expect(roadwayAreaValidation(formFalues, false)).toBeUndefined();
  });

  it('проверка на округление #2', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const roadway_prkg_auto_clean_area = 0;
    const roadway_noprkg_auto_clean_area = 20773.8;
    const roadway_prkg_manual_clean_area = 20773.99;
    const roadway_noprkg_manual_clean_area = 20774;
    const roadway_area = 20773.79;

    // Act
    const formFalues = {
      roadway_area,
      roadway_noprkg_auto_clean_area,
      roadway_noprkg_manual_clean_area,
      roadway_prkg_auto_clean_area,
      roadway_prkg_manual_clean_area,
    };

    // Assert
    expect(roadwayAreaValidation(formFalues, false)).toStrictEqual(error2);
  });
});
