import { RANDOM_NUMBER } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import DtsCard from './Dts';

vi.mock('app/components/card/common/validation');
vi.mock('app/components/card/ogh/submit');
vi.mock('redux-form', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    reduxForm: vi.fn(() => (Component) => {
      return Component;
    }),
  };
});

vi.mock('react-redux', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    connect: vi.fn(() => (ba) => {
      return ba;
    }),
  };
});

describe('🐛 DTSCard', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = {
      card: { bord_begin: 1, flat_element_type: [], type_id: RANDOM_NUMBER },
      card_type: 'TROLLEYBUS_CONTACT_NETWORK',
      editMode: true,
      formValues: {
        bord_begin: 1,
      },
      isCurrentObjectIsExternalSystem: false,
      mode: {},
    };

    // 🔥 Act
    const wrapper = shallow(<DtsCard {...props} />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<CardContainer />');
  });

  it('🧪 CoatingGroupIdRF', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const changeFieldValueSpy = vi.fn();
    const props = {
      card: { bord_begin: 1, flat_element_type: [], type_id: RANDOM_NUMBER },
      card_type: 'TROLLEYBUS_CONTACT_NETWORK',
      changeFieldValue: changeFieldValueSpy,
      editMode: true,
      formValues: {
        bord_begin: 1,
      },
      isCurrentObjectIsExternalSystem: false,
      mode: {},
    };

    // 🔥 Act
    const wrapper = shallow(<DtsCard {...props} />);
    const coatingGroupIdRFProps = wrapper.find('CoatingGroupIdRF').props();
    const { withChange } = coatingGroupIdRFProps;
    withChange();

    // ❓ Assert
    expect(coatingGroupIdRFProps).toStrictEqual({
      disabled: true,
      label: 'Вид покрытия',
      required: true,
      withAll: false,
      withChange: expect.any(Function),
    });
    expect(changeFieldValueSpy).toHaveBeenCalledWith('coating_type_id', null);
  });

  it('🧪 CoatingTypeIdRF', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const changeFieldValueSpy = vi.fn();
    const props = {
      card: { bord_begin: 1, flat_element_type: [], type_id: RANDOM_NUMBER },
      card_type: 'TROLLEYBUS_CONTACT_NETWORK',
      changeFieldValue: changeFieldValueSpy,
      coatingTypes: [1, 2, 3],
      editMode: true,
      formValues: {
        bord_begin: 1,
      },
      isCurrentObjectIsExternalSystem: false,
      mode: {},
    };

    // 🔥 Act
    const wrapper = shallow(<DtsCard {...props} />);
    const coatingTypeIdRFProps = wrapper.find('CoatingTypeIdRF').props();
    const { filter } = coatingTypeIdRFProps;
    const expectedFilter = filter([]);

    // ❓ Assert
    expect(coatingTypeIdRFProps).toStrictEqual({
      disabled: true,
      filter: expect.any(Function),
      label: 'Вид покрытия (уточнение)',
      required: true,
      withAll: false,
    });
    expect(expectedFilter).toStrictEqual([1, 2, 3]);
  });

  it('🧪 CleaningTypeRF', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = {
      card: { bord_begin: 1, flat_element_type: [], type_id: RANDOM_NUMBER },
      card_type: 'TROLLEYBUS_CONTACT_NETWORK',
      editMode: true,
      formValues: {
        bord_begin: 1,
      },
      isCurrentObjectIsExternalSystem: false,
      mode: {},
    };

    // 🔥 Act
    const wrapper = shallow(<DtsCard {...props} />);
    const cleaningTypeRFProps = wrapper.find('CleaningTypeRF').props();

    // ❓ Assert
    expect(cleaningTypeRFProps).toStrictEqual({
      disabled: false,
      required: true,
    });
  });

  it('🧪 CleaningTypeRF disabled', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = {
      card: { bord_begin: 1, flat_element_type: [], type_id: RANDOM_NUMBER },
      card_type: 'TROLLEYBUS_CONTACT_NETWORK',
      editMode: false,
      formValues: {
        bord_begin: 1,
      },
      isCurrentObjectIsExternalSystem: false,
      mode: {},
    };

    // 🔥 Act
    const wrapper = shallow(<DtsCard {...props} />);
    const cleaningTypeRFProps = wrapper.find('CleaningTypeRF').props();

    // ❓ Assert
    expect(cleaningTypeRFProps).toStrictEqual({
      disabled: true,
      required: true,
    });
  });

  it('🧪 fields', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    const props = {
      card: { bord_begin: 1, flat_element_type: [], type_id: RANDOM_NUMBER },
      card_type: 'TROLLEYBUS_CONTACT_NETWORK',
      editMode: false,
      formValues: {
        bord_begin: 1,
      },
      isCurrentObjectIsExternalSystem: false,
      mode: {},
    };

    //🔥 Act
    const wrapper = shallow(<DtsCard {...props} />);

    //❓ Assert
    expect(wrapper.exists('DtsTypeIdRFField')).toBeTruthy();
  });
});
