import { createIntersectionsMessage } from 'app/utils/card/checkGeometry/helpers/createIntersectionsMessage';
import messages from 'app/utils/card/checkGeometry/messages';
import { useMapglContext } from 'core/uiKit/components/DTW/contexts/MapglContextProvider';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';

import { useReduxCheckGeometryButton } from '../useRedux.CheckGeometry.Button';

/**
 * Получение геометрий пересечения.
 *
 * @param parags - Параметры.
 * @param parags.showAlert - Показ сообщения.
 * @returns Мутация.
 */
export const useDrawIntersectionsGeometriesDTW = ({
  showAlert,
}: {
  showAlert: (message: JSX.Element | string) => void;
}) => {
  const { fetchCheckGeometryIntersections } = useReduxCheckGeometryButton();

  const { drawIntersections } = useMapglContext();

  return useMutationAdaptor(fetchCheckGeometryIntersections, {
    //

    /**
     * При ошибке.
     *
     */
    onError: () => {
      showAlert(messages.success);
    },

    /**
     * A.
     *
     * @param data - A.
     */
    onSuccess: (data) => {
      if (!data.intersected) {
        throw new Error(messages.errorIntersections);
      }

      showAlert(
        createIntersectionsMessage(data.geometry, messages.errorIntersections),
      );
      drawIntersections(data.geometry);
    },
  });
};
