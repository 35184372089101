import { IconButton as IconButtonMUI } from 'core/uiKit/material-ui';
import { Tooltip as TooltipMUI } from 'core/uiKit/material-ui';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  disableFocusRipple: PropTypes.bool,
  disableRipple: PropTypes.bool,
  disabled: PropTypes.bool,
  edge: PropTypes.bool,
  id: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string,
  type: PropTypes.string,
};

const defaultProps = {
  edge: false,
};

/**
 * Компонент иконки.
 *
 * @deprecated
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
function IconButton(props) {
  const { disabled, title, ...rest } = props;
  const ButtonComponent = <IconButtonMUI disabled={disabled} {...rest} />;

  return !disabled && title ? (
    <TooltipMUI placement="right-end" title={title} {...props}>
      {ButtonComponent}
    </TooltipMUI>
  ) : (
    ButtonComponent
  );
}

IconButton.propTypes = propTypes;
IconButton.defaultProps = defaultProps;

export default IconButton;
