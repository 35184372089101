import { AutocompleteAsync } from 'core/uiKit/inputs/selects/AutocompleteAsync';
import React from 'react';

import { fetchOghTypesAutocomplete } from './fetchOghTypes.Autocomplete';

export const SUB_TYPE_ID_NAME = 'subTypeId';

export interface ISubTypeIdProps {
  //

  /**
   * Id типа карточки.
   */
  odsType: number;

  /**
   * Id родительского типа.
   */
  odsParentType?: number;
  className?: string;

  /**
   * Человекочитаемое название поле.
   */
  label?: string;
}

/**
 * ## Тип.
 *
 * @param props - Properties.
 * @param props.odsType - Id типа карточки.
 * @param props.odsParentType - Id родительского типа.
 * @param props.otherProps - Properties.
 * @returns JSX.Element.
 */
export const SubTypeId = ({
  odsType,
  odsParentType,
  ...otherProps
}: ISubTypeIdProps) => {
  return (
    <AutocompleteAsync
      label={'Тип'}
      {...otherProps}
      additionalDataForRequest={{
        parentTypeId: odsParentType,
        typeId: odsType,
      }}
      fetchFunction={fetchOghTypesAutocomplete}
      name={SUB_TYPE_ID_NAME}
    />
  );
};
