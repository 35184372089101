import { useChangeFieldValueRF } from 'app/actions/odsObjectActions/useChangeFieldValueRF';
import { useFormValuesCardRF } from 'app/actions/odsObjectActions/useFormValuesCardRF';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { RANDOM_WORD } from 'core/forTesting/constants';
import { CLEANING_TYPE_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CleaningType/index';
import { COATING_GROUP_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingGroupId/index';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import { CleaningTypeRfField } from './CleaningTypeRF.Field';

vi.mock('app/pages/cardsOgh/hooks/useEditMode');
vi.mock('app/actions/odsObjectActions/useFormValuesCardRF');
vi.mock('app/actions/odsObjectActions/useChangeFieldValueRF');

vi.mock('react', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,

    /**
     * Переопределение useEffect.
     *
     * @param {Function} fn - Функция.
     * @returns {void}
     */
    useEffect: (fn) => fn(),
    useState: vi.fn(),
  };
});

describe('🐛 CleaningTypeRfField', function () {
  it('🧪 test called changeFieldValue', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    const changeFieldValue = vi.fn();

    useEditMode.mockReturnValue({ editMode: true });
    useFormValuesCardRF.mockReturnValue({
      [COATING_GROUP_ID_NAME]: 2,
      foo: RANDOM_WORD,
    });
    useChangeFieldValueRF.mockReturnValue(changeFieldValue);

    //🔥 Act
    shallow(<CleaningTypeRfField />);

    //❓ Assert
    expect(changeFieldValue).toBeCalledWith(CLEANING_TYPE_NAME, null);
  });

  it('🧪 test called filter fn', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    const changeFieldValue = vi.fn();
    const dict = [
      { id: 0, name: 0 },
      { id: 1, name: 1 },
      { id: 2, name: 2 },
    ];

    useEditMode.mockReturnValue({ editMode: true });
    useFormValuesCardRF.mockReturnValue({
      [COATING_GROUP_ID_NAME]: 2,
      foo: RANDOM_WORD,
    });
    useChangeFieldValueRF.mockReturnValue(changeFieldValue);

    //🔥 Act
    const wrapper = shallow(<CleaningTypeRfField />);
    const { filter } = wrapper.props();

    //❓ Assert
    expect(filter(dict)).toStrictEqual([
      { id: 1, name: 1 },
      { id: 2, name: 2 },
    ]);
  });

  it('🧪 test called filter fn case 2', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    const changeFieldValue = vi.fn();
    const dict = [
      { id: 0, name: 0 },
      { id: 1, name: 1 },
      { id: 2, name: 2 },
    ];

    useEditMode.mockReturnValue({ editMode: true });
    useFormValuesCardRF.mockReturnValue({
      foo: RANDOM_WORD,
    });
    useChangeFieldValueRF.mockReturnValue(changeFieldValue);

    //🔥 Act
    const wrapper = shallow(<CleaningTypeRfField />);
    const { filter } = wrapper.props();

    //❓ Assert
    expect(filter(dict)).toStrictEqual([
      { id: 0, name: 0 },
      { id: 1, name: 1 },
      { id: 2, name: 2 },
    ]);
  });
});
