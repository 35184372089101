import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { OghCardHeaderFF } from 'app/pages/cardsOgh/components/headerFF/OghHeaderFF';
import Versions from 'app/pages/cardsOgh/components/tables/Versions/Versions';
import { CoatingFaceTypeIdFFField } from 'app/pages/cardsOgh/EngineerBuilding/fields/CoatingFaceTypeIdFF.Field';
import { IsDiffHeightMarkFf } from 'core/form/finalForm/fields/prepared/checkboxes/IsDiffHeightMark.FF';
import { NoCalcFF } from 'core/form/finalForm/fields/prepared/checkboxes/NoCalc.FF';
import { TotalAreaGeoFF } from 'core/form/finalForm/fields/prepared/numberField/TotalAreaGeo.FF';
import { CardContainer } from 'core/uiKit/components/card.components/CardContainer';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { TabsCardOGH } from 'core/uiKit/components/card.components/TabsCardOGH';
import { TabsCardOGHItem } from 'core/uiKit/components/card.components/TabsCardOGH/TabsCardOGH.Item';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import React from 'react';

import { MaterialIdFFField } from '../fields/MaterialIdFF.Field';
import { useMode } from '../hooks/useMode';
import { CleaningTypeIdFFField } from './fields/CleaningTypeIdFF.Field';
import { CoatingGroupIdFFField } from './fields/coatingFields/CoatingGroupIdFF.Field';
import { CoatingTypeIdFFField } from './fields/coatingFields/CoatingTypeIdFF.Field';
import { EngineerBuildingTypeIdFfField } from './fields/EngineerBuildingTypeIdFF.Field';
import { FaceAreaFFField } from './fields/FaceAreaFF.Field';
import { QuantityFFField } from './fields/QuantityFf.Field';
import { UnitIdFFField } from './fields/UnitIdFF.Field';

/**
 * Левая панель Инженерных сооружений.
 *
 * @returns {JSX.Element}
 */
export const LeftPanelEngineerBuilding = () => {
  const { editMode } = useMode();
  const { rootId = 0 } = useGetCurrentCard();
  const isCreatedPassport = Boolean(rootId);

  return (
    <CardContainer>
      <OghCardHeaderFF />
      <TabsCardOGH>
        <TabsCardOGHItem tabTitle={'Свойства'}>
          <EngineerBuildingTypeIdFfField disabled={!editMode} />
          <ParentInfo />
          <TabsCardOGH>
            <TabsCardOGHItem tabTitle={'Исходные данные'}>
              <FieldsSplitColumns>
                <QuantityFFField disabled={!editMode} required={true} />
                <TotalAreaGeoFF disabled={true} digits={2} />
                <UnitIdFFField />
                <MaterialIdFFField />
                <CoatingGroupIdFFField />
                <CoatingTypeIdFFField />
                <CoatingFaceTypeIdFFField />
                <FaceAreaFFField />
                <CleaningTypeIdFFField />
                <NoCalcFF disabled={!editMode} />
                <IsDiffHeightMarkFf disabled={!editMode} />
              </FieldsSplitColumns>
            </TabsCardOGHItem>
          </TabsCardOGH>
        </TabsCardOGHItem>
        <TabsCardOGHItem tabTitle={'Версии'} hide={!isCreatedPassport}>
          <Versions />
        </TabsCardOGHItem>
      </TabsCardOGH>
    </CardContainer>
  );
};
