import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { useFieldRF } from 'core/form/reduxForm/hooks/useField.RF';
import { useFormValuesCardRF } from 'core/form/reduxForm/hooks/useFormValuesCardRF';
import { MAF_TYPE_LEVEL_2_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/MafTypeLevel2';
import { useEffect, useState } from 'react';

/**
 * Select RF  уточнение.
 *
 * @returns
 */
export const useSetupMafTypeLevel2IdField = () => {
  const [haveOptions, setHaveOptions] = useState(false);

  const { onChange } = useFieldRF<string>(MAF_TYPE_LEVEL_2_ID_NAME);
  const { typeId } = useGetParentCard();

  const { editMode } = useMode();
  const { maf_type_level1_id = 0 } = <
    {
      maf_type_level1_id: number;
    }
  >useFormValuesCardRF();

  useEffect(() => {
    if (editMode && maf_type_level1_id) {
      onChange('');
    }
    // eslint-disable-next-line
  }, [maf_type_level1_id]);

  /**
   * Фильтрация Уточнения.
   *
   * @param dict - Справочные значения.
   * @returns Отфильтрованные справочные значения.
   */
  const filter = (
    dict: {
      id: number;
      mafTypeList: {
        mafTypeLevel1Id?: number;
        parentTypeIdList?: number[];
      }[];
    }[],
  ) => {
    const options = (dict || []).filter((item) =>
      item.mafTypeList.some((item) => {
        return (
          item?.mafTypeLevel1Id === maf_type_level1_id &&
          item?.parentTypeIdList?.includes(typeId)
        );
      }),
    );

    setHaveOptions(Boolean(options.length));
    return options;
  };

  const disabled = !(editMode && haveOptions);

  return { disabled, filter };
};
