import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import CardAbstract from 'app/components/card/common/CardAbstract';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import { IsDiffHeightMarkField } from 'app/components/card/ogh/components/fields/checkBoxFields/IsDiffHeightMark.Field';
import printOghCardHeader from 'app/components/card/ogh/header/oghHeader';
import { IsFilled } from 'app/components/card/ogh/ImprovementTerritoryCard/components/IsFilled';
import MafType from 'app/components/card/ogh/LittleForm/components/MafType';
import VersionsTemplate from 'app/components/card/ogh/versions/VersionsTemplate';
import {
  IdRfidRF,
  KeyboardDatePickerRF,
  NumberFieldRF,
} from 'core/form/reduxForm/fields';
import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import { ErzStateIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/ErzStateId.RF';
import { UnitIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/UnitId.RF';
import { OtherDocumentsRF } from 'core/form/reduxForm/fields/prepared/tables/documentTables/OtherDocuments.RF';
import { EaisIdRF } from 'core/form/reduxForm/fields/prepared/textField/EaisId.RF';
import { CardContainer } from 'core/uiKit/components/card.components/CardContainer';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { TextField } from 'core/uiKit/inputs/TextField';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import React from 'react';

const types = ['little_form'];
const Versions = VersionsTemplate(types);

const tabs = [
  {
    id: 'characteristics-tab',
    name: 'Исходные данные',
  },
  {
    id: 'docs-tab',
    name: 'Документы',
  },
];

/**
 * Компонент LittleFormComponent.
 *
 * @param {object} props - Пропсы.
 * @returns {React.ReactElement}
 */
const LittleForm = (props) => {
  const { typeId: parentTypeId } = useGetParentCard();
  const {
    card,
    changeFieldValue,
    createMode,
    editMode,
    formValues,
    isEditCurrentObjectIsExternalSystem,
    // isEditableMaterial,
    mafTypeLevel1,
    mafTypeLevel2,
    mafTypeLevel3,
    mode,
    onChangeMafTypeLevel2Id,
    required,
    units,
  } = props;

  const { type_id: typeId } = card;

  // Обеспечить выполнение следующего алгоритма ограничения (ФЛК):
  // "Ед.изм. - шт." - "Количество" -  целое число (не может быть отрицательным)
  // "Ед.изм. - п.м./кв.м - "Количество" - целое и дробное число (не может быть отрицательным)
  const unit = units.find((item) => item.id === formValues.unit_id);
  const quantityDigits = ['square_meters', 'running_meters'].includes(
    unit?.code,
  )
    ? 2
    : 0;

  return (
    <CardContainer>
      <CardAbstract>
        <div id="editor-card">
          {printOghCardHeader(card, mode)}
          {card && objectTabs(typeId)}
          <div id="props">
            <MafType
              changeFieldValue={changeFieldValue}
              parentTypeId={parentTypeId}
              editable={editMode}
              isEditCurrentObjectIsExternalSystem={
                isEditCurrentObjectIsExternalSystem
              }
              mafTypeLevel1={mafTypeLevel1}
              mafTypeLevel1Id={formValues.maf_type_level1_id}
              mafTypeLevel2={mafTypeLevel2}
              mafTypeLevel3={mafTypeLevel3}
              required={required}
              typeId={typeId}
              onChangeMafTypeLevel2Id={onChangeMafTypeLevel2Id}
            />

            <FieldsSplitColumns>
              <ParentInfo />
              <IsDiffHeightMarkField />
            </FieldsSplitColumns>

            <Tabs tabs={tabs} />

            <div id="characteristics">
              <FieldsSplitColumns>
                <UnitIdRF required={false} disabled={true} />
                <NumberFieldRF
                  label={'Количество МАФ'}
                  name={'quantity'}
                  decimalScale={quantityDigits}
                  disabled={true}
                />
                <SelectRF
                  label={'Материал'}
                  name={'material_id'}
                  options={card.material}
                  disabled={!editMode}
                />
                <TextField
                  label={'Принадлежность элемента к зоне территории'}
                  name={'zone_name'}
                  value={card.zone_name || ''}
                  disabled={true}
                />
                <IdRfidRF disabled={!editMode} />
                <KeyboardDatePickerRF
                  label={'Дата установки'}
                  name={'installation_date'}
                  disabled={!editMode}
                />
                <KeyboardDatePickerRF
                  label={'Срок эксплуатации'}
                  name={'lifetime'}
                  disabled={!editMode}
                />
                <KeyboardDatePickerRF
                  label={'Гарантийный срок'}
                  name={'guarantee_period'}
                  disabled={!editMode}
                  minDate={formValues?.installation_date || ''}
                />
                <EaisIdRF disabled={true} />
                <IsFilled />
                <ErzStateIdRF disabled={true} />
                <KeyboardDatePickerRF
                  label={'Дата состояния (из ЕРЗ)'}
                  name={'erz_date'}
                  disabled={true}
                />
              </FieldsSplitColumns>
            </div>
            <div hidden={true} id={'docs'}>
              <OtherDocumentsRF />
            </div>
          </div>
          <div hidden={true} id="versions">
            <Versions
              card={card}
              disabled={createMode}
              key={`versions-${card.root_id}`}
            />
          </div>
        </div>
      </CardAbstract>
    </CardContainer>
  );
};

export default LittleForm;
