import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import LittleForm from './LittleFormComponent';

vi.mock('app/api/hooks/useGetParentCard');

describe('🐛 LittleFormComponentSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    /**
     * Функция isEditableMaterial.
     *
     * @returns {boolean}
     */
    const isEditableMaterial = () => true;
    useGetParentCard.mockReturnValue({ typeId: 1 });
    const props = {
      card: {
        type_id: 37,
      },
      editMode: true,
      formValues: {
        maf_type_level1_id: 2,
        unit_id: 1,
      },
      isEditCurrentObjectIsExternalSystem: true,
      isEditableMaterial,
      required: true,
      units: [{ id: 2 }],
    };

    // 🔥 Act
    const wrapper = shallow(<LittleForm {...props} />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<CardContainer />');
  });

  it('🧪 fields', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    /**
     * Функция isEditableMaterial.
     *
     * @returns {boolean}
     */
    const isEditableMaterial = () => true;
    useGetParentCard.mockReturnValue({ typeId: 1 });

    const props = {
      card: {
        type_id: 37,
      },
      editMode: false,
      formValues: {
        maf_type_level1_id: 2,
        unit_id: '',
      },
      isEditCurrentObjectIsExternalSystem: true,
      isEditableMaterial,
      required: true,
      units: [],
    };

    // 🔥 Act
    const wrapper = shallow(<LittleForm {...props} />);

    // ❓ Assert
    expect(wrapper.find('UnitIdRF').props()).toStrictEqual({
      disabled: true,
      required: false,
    });

    // ❓ Assert
    expect(wrapper.find('NumberFieldRF').props()).toMatchObject({
      decimalScale: 0,
      disabled: true,
      label: 'Количество МАФ',
      name: 'quantity',
    });
  });
});
