import { initEngineeringTab } from 'app/components/card/ogh/Odh/components/tabs/EngineeringStructures.Tab/initEngineeringTab';

describe('InitEngineeringTabJestSpec', function () {
  it('test', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const parse = jest.fn(() => 1);

    // Act

    // Assert
    expect(initEngineeringTab(parse)).toStrictEqual({
      bridge_psc: 1,
      bridge_qty: 1,
      bridge_sqr: 1,
      cable_fancing_psc: 1,
      decor_psc: 1,
      lamp_qty: 1,
      light_psc: 1,
      light_qty: 1,
      pavilion_qty: 1,
      pedestrian_psc: 1,
      pedestrian_qty: 1,
      pedestrian_sqr: 1,
      police_qty: 1,
      police_sqr: 1,
      sandbase_qty: 1,
      sandbase_sqr: 1,
      snow_psc: 1,
      support_qty: 1,
      tlight_qty: 1,
      transform_qty: 1,
      tube_psc: 1,
      tube_qty: 1,
      urn_qty: 1,
      wall_psc: 1,
      water_psc: 1,
      сase_outside_light_qty: 1,
    });
  });
});
