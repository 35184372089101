import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { vi } from 'vitest';

import { useSetupDtsTypeIdRFField } from './useSetup.DtsTypeId.RF.Field';

vi.mock('app/api/hooks/useIsEditCurrentObjectIsExternalSystem');
vi.mock('app/pages/cardsOgh/hooks/useMode');

vi.mock('app/api/hooks/autocomplete/useGetDictionary');
vi.mock('app/api/hooks/useGetParentCard');

/**
 * Mock Default.
 *
 * @returns {void}
 */
const mockDefault = () => {
  useIsEditCurrentObjectIsExternalSystem.mockReturnValue();
  useMode.mockReturnValue({});
};

describe('🐛 spec useSetup.DtsTypeId.RF.Field', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    mockDefault();

    // 🧹 clear mock

    //🔥 Act
    const res = useSetupDtsTypeIdRFField();

    //❓ Assert
    expect(res).toStrictEqual({
      disabled: true,
      filter: expect.any(Function),
      options: expect.any(Array),
    });
  });

  it('🧪 options', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    mockDefault();

    //🔥 Act
    const { options } = useSetupDtsTypeIdRFField();

    //❓ Assert
    expect(options).toStrictEqual(expectedOptions);
  });

  it('🧪 filter', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    mockDefault();

    // 🧹 clear mock
    useGetParentCard.mockReturnValue({ type_id: 39 });

    //🔥 Act
    const { filter } = useSetupDtsTypeIdRFField();
    const res = filter(expectedOptions);

    //❓ Assert
    expect(res).toStrictEqual(expectedFiltered);
  });
});

const expectedOptions = [
  {
    id: 103,
    name: 'Беговая дорожка',
    parent_ogh_object_type_list: [38],
  },
  {
    id: 50,
    name: 'Беговая дорожка',
    parent_ogh_object_type_list: [40],
  },
  {
    id: 32,
    name: 'Велосипедная дорожка',
    parent_ogh_object_type_list: [38, 40, 49, 39],
  },
  {
    id: 42,
    name: 'Искусственная дорожная неровность',
    parent_ogh_object_type_list: [38, 49, 39],
  },
  {
    id: 105,
    name: 'Лестница',
    parent_ogh_object_type_list: [38],
  },
  {
    id: 101,
    name: 'Мост пешеходный',
    parent_ogh_object_type_list: [49],
  },
  {
    id: 104,
    name: 'Пандус',
    parent_ogh_object_type_list: [38],
  },
  {
    id: 27,
    name: 'Пешеходная дорожка',
    parent_ogh_object_type_list: [38, 40, 49, 39],
  },
  {
    id: 25,
    name: 'Проезд',
    parent_ogh_object_type_list: [38, 40, 49, 39],
  },
  {
    id: 102,
    name: 'Рельефный пешеходный переход',
    parent_ogh_object_type_list: [38],
  },
  {
    id: 43,
    name: 'Технический (технологический) тротуар',
    parent_ogh_object_type_list: [38, 40, 49, 39],
  },
  {
    id: 26,
    name: 'Тротуар',
    parent_ogh_object_type_list: [38, 40, 49, 39],
  },
  {
    id: 100,
    name: 'Экологические тропы',
    parent_ogh_object_type_list: [49],
  },
];

const expectedFiltered = [
  {
    id: 32,
    name: 'Велосипедная дорожка',
    parent_ogh_object_type_list: [38, 40, 49, 39],
  },
  {
    id: 42,
    name: 'Искусственная дорожная неровность',
    parent_ogh_object_type_list: [38, 49, 39],
  },
  {
    id: 27,
    name: 'Пешеходная дорожка',
    parent_ogh_object_type_list: [38, 40, 49, 39],
  },
  {
    id: 25,
    name: 'Проезд',
    parent_ogh_object_type_list: [38, 40, 49, 39],
  },
  {
    id: 43,
    name: 'Технический (технологический) тротуар',
    parent_ogh_object_type_list: [38, 40, 49, 39],
  },
  {
    id: 26,
    name: 'Тротуар',
    parent_ogh_object_type_list: [38, 40, 49, 39],
  },
];
