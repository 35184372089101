import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { RANDOM_ID, RANDOM_NUMBER } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import React from 'react';
import { vi } from 'vitest';

import { CharacteristicsTabRelief } from './Characteristics.Tab.Relief';

vi.mock('app/api/hooks/useIsEditCurrentObjectIsExternalSystem');

describe('🐛 spec Characteristics.Tab.Relief', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)

    const card = {
      attribute: {
        coating_face_type_id: { coating_type: RANDOM_ID },
        coating_group_id: { coating_group: RANDOM_ID },
        coating_type_id: { coating_type: RANDOM_ID },
        face_area: RANDOM_NUMBER,
        is_diff_height_mark: true,
      },
    };
    useIsEditCurrentObjectIsExternalSystem.mockReturnValue(true);

    // 🧹 clear mock

    //🔥 Act
    const wrapper = shallow(<CharacteristicsTabRelief card={card} />);

    //❓ Assert
    expect(wrapper.text()).toBe('<FieldsSplitColumns /><FieldsSplitColumns />');
  });
});
