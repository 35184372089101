import { resetTabs, Tabs } from 'app/components/card/common/tabs';
import { RANDOM_ID, RANDOM_WORD } from 'core/forTesting/constants';
import { mount } from 'enzyme';

import PropsTabs from './PropsTabs';

jest.mock('app/components/card/common/tabs', () => ({
  ...jest.requireActual('app/components/card/common/tabs'),
  resetTabs: jest.fn(),
}));

/**
 * Тестовый компонент.
 *
 * @param {object} props - Пропсы.
 * @param {React.ReactNode} props.children - Дети.
 * @returns {JSX.Element}
 */
const Foo = ({ children }) => {
  return <p>{children}</p>;
};

describe('PropsTabsJestSpec', function () {
  it('test', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    const Component = PropsTabs([
      { id: RANDOM_ID, name: RANDOM_WORD },
      { Component: Foo, id: RANDOM_ID, name: RANDOM_WORD },
    ]);

    // Act
    const wrapper = mount(<Component />);

    // Assert
    expect(resetTabs).toBeCalledTimes(1);
    expect(wrapper.find(Tabs).props()).toStrictEqual({
      tabs: [
        { id: RANDOM_ID, name: RANDOM_WORD },
        { Component: Foo, id: RANDOM_ID, name: RANDOM_WORD },
      ],
    });
  });
});
