import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import isMandatory from 'app/utils/card/isMandatory';
import {
  RANDOM_ID,
  RANDOM_NUMBER,
  RANDOM_WORD,
} from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import {
  FLAT_ELEMENT_TYPE_ID_NAME,
  FlatElementTypeIdField,
} from './FlatElementTypeId.Field';

vi.mock('app/api/hooks/useGetCurrentCard');
vi.mock('app/api/hooks/useIsEditCurrentObjectIsExternalSystem');
vi.mock('app/pages/cardsOgh/hooks/useMode');
vi.mock('app/utils/card/isMandatory');

describe('🐛 FlatElementTypeIdField', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useGetCurrentCard.mockReturnValue({
      flat_element_type: [],
      typeId: RANDOM_NUMBER,
    });
    useMode.mockReturnValue({ editMode: false });
    useIsEditCurrentObjectIsExternalSystem.mockReturnValue(true);
    isMandatory.mockReturnValue(true);

    // 🔥 Act
    const wrapper = shallow(<FlatElementTypeIdField />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<SelectRF />');
  });
  it('🧪 isMandatory', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useGetCurrentCard.mockReturnValue({
      flat_element_type: [],
      typeId: RANDOM_NUMBER,
    });
    useMode.mockReturnValue({ editMode: false });
    useIsEditCurrentObjectIsExternalSystem.mockReturnValue(true);
    isMandatory.mockReturnValue(true);

    // 🔥 Act
    shallow(<FlatElementTypeIdField />);

    // ❓ Assert

    expect(isMandatory).toHaveBeenCalledWith(
      RANDOM_NUMBER,
      FLAT_ELEMENT_TYPE_ID_NAME,
      false,
    );
  });
  it('🧪 props', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useGetCurrentCard.mockReturnValue({
      flat_element_type: [{ id: RANDOM_ID, name: RANDOM_WORD }],
      typeId: RANDOM_NUMBER,
    });
    useMode.mockReturnValue({ editMode: true });
    useIsEditCurrentObjectIsExternalSystem.mockReturnValue(true);
    isMandatory.mockReturnValue(true);

    // 🔥 Act

    const wrapper = shallow(<FlatElementTypeIdField />);

    // ❓ Assert

    expect(wrapper.props()).toStrictEqual({
      disabled: false,
      label: 'Тип',
      name: FLAT_ELEMENT_TYPE_ID_NAME,
      options: [{ id: RANDOM_ID, name: RANDOM_WORD }],
      required: true,
    });
  });
});
