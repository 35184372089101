import { RANDOM_NUMBER } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import BuildingCard, { onSubmit } from './building';
import submitCard from './submit';

vi.mock('app/components/card/common/validation');
vi.mock('./submit');
vi.mock('redux-form', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    reduxForm: vi.fn(() => (Component) => {
      return Component;
    }),
  };
});

vi.mock('react-redux', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    connect: vi.fn(() => (ba) => {
      return ba;
    }),
  };
});

describe('BuildingCard', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = {
      card: { bord_begin: 1, flat_element_type: [], type_id: RANDOM_NUMBER },
      card_type: 'BuildingCard',
      currentValues: {
        buildings_type_id: 84,
      },
      editMode: true,
      formValues: {
        bord_begin: 1,
      },
      isCurrentObjectIsExternalSystem: false,
      mode: {},
      resetBtiGeometry: vi.fn(),
    };

    // 🔥 Act

    const wrapper = shallow(<BuildingCard {...props} />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<CardContainer />');
  });
  it('BuildingsTypeIdRF', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = {
      card: { bord_begin: 1, flat_element_type: [], type_id: RANDOM_NUMBER },
      card_type: 'BuildingCard',
      currentValues: {
        buildings_type_id: 84,
      },
      editMode: true,
      formValues: {
        bord_begin: 1,
      },
      isCurrentObjectIsExternalSystem: false,
      mode: {},
      resetBtiGeometry: vi.fn(),
    };

    // 🔥 Act

    const wrapper = shallow(<BuildingCard {...props} />);

    // ❓ Assert
    expect(wrapper.find('BuildingsTypeIdRF').props()).toStrictEqual({
      disabled: true,
    });
  });
});

describe('🐛 onSubmit', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = { card: { type_id: 31 } };
    const onSubmitProps = onSubmit.bind({ props });

    // 🔥 Act

    onSubmitProps();

    // ❓ Assert
    expect(submitCard).toHaveBeenCalledTimes(1);
  });
});
