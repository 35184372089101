import { pathExaminationOGHApplication } from 'app/pages/certificationOfOgh/examinationOGH.Application/path.ExaminationOGH.Application';
import { pathCertificationOfOgh } from 'app/pages/certificationOfOgh/path.CertificationOfOgh';
import { RANDOM_NUMBER } from 'core/forTesting/constants';
import { shallow } from 'enzyme';

import { BrIdLinkCell } from './BrIdLink.Cell';

describe('BrIdLinkCell', function () {
  it('test render', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const wrapper = shallow(<BrIdLinkCell value={RANDOM_NUMBER} />);

    // Assert
    expect(wrapper.props()).toStrictEqual({
      children: RANDOM_NUMBER,
      to: `/${pathCertificationOfOgh.path}/${pathExaminationOGHApplication.path}/${RANDOM_NUMBER}`,
    });
    expect(wrapper.text()).toBe('' + RANDOM_NUMBER);
  });

  it('test value undefined', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const wrapper = shallow(<BrIdLinkCell />);

    // Assert
    expect(wrapper.text()).toBe('');
  });
});
