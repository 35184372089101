import { useGetDictionary } from 'app/api/hooks/useGetDictionary';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { useFormValuesCardRF } from 'core/form/reduxForm/hooks/useFormValuesCardRF';

/**
 * Setup Number Field __Количество мест шт__.
 *
 * @returns
 */
export const useSetupMafQuantityCharacteristicsField = () => {
  const { data: options = [] } = useGetDictionary('maf_type_level1');
  const { editMode } = useMode();
  const { maf_type_level1_id = 0 } = useFormValuesCardRF<{
    maf_type_level1_id: number;
  }>();

  // 👇 пока @ts-ignore, потому что нет дженерика
  //  @ts-ignore
  const option = options.find((item) => item.id === maf_type_level1_id);

  const label = option?.quantity_name || 'Количество мест, шт.';
  const disabled = !(option?.quantity_name && editMode);
  const required = Boolean(option?.quantity_name);

  return {
    disabled,
    label,
    options,
    required,
  };
};
