import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { useCoatingTypeRoadIdRFTramRails } from 'app/components/card/ogh/tram_rails/fields/CoatingTypeRoadIdRF.TramRails/useCoatingTypeRoadIdRFTramRails';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import getFormValues from 'app/selectors/form/getFormValues';
import { RANDOM_BOOL, RANDOM_NUMBER } from 'core/forTesting/constants';
import { useSelector } from 'react-redux';

jest.mock('app/pages/cardsOgh/hooks/useEditMode');
jest.mock('app/api/hooks/useIsCurrentObjectIsExternalSystem');
jest.mock('app/api/hooks/useGetParentCard');
jest.mock('react-redux');
jest.mock('app/selectors/form/getFormValues');

describe('UseCoatingTypeRoadIdRfTramRailsJestSpec', function () {
  it('test called getFormValues', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useEditMode.mockReturnValue({ editMode: RANDOM_BOOL });
    useIsCurrentObjectIsExternalSystem.mockReturnValue(RANDOM_BOOL);
    useGetParentCard.mockReturnValue({ typeId: RANDOM_NUMBER });
    useSelector.mockImplementation((fn) => {
      fn({ foo: RANDOM_NUMBER });
    });

    // Act
    useCoatingTypeRoadIdRFTramRails();

    // Assert
    expect(getFormValues).toBeCalledTimes(1);
    expect(getFormValues).toHaveBeenCalledWith({ foo: RANDOM_NUMBER });
  });

  it('test filter no data', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useEditMode.mockReturnValue({ editMode: RANDOM_BOOL });
    useIsCurrentObjectIsExternalSystem.mockReturnValue(RANDOM_BOOL);
    useGetParentCard.mockReturnValue({});
    useSelector.mockReturnValue({});

    // Act
    const { filter } = useCoatingTypeRoadIdRFTramRails();

    // Assert
    expect(filter()).toStrictEqual([]);
  });

  it('test filter', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useEditMode.mockReturnValue({ editMode: RANDOM_BOOL });
    useIsCurrentObjectIsExternalSystem.mockReturnValue(RANDOM_BOOL);
    useGetParentCard.mockReturnValue({ typeId: 49 });
    useSelector.mockReturnValue({ coating_group_road_id: 2 });

    // Act
    const { filter } = useCoatingTypeRoadIdRFTramRails();

    // Assert
    expect(filter(dict)).toStrictEqual([
      {
        class_id: null,
        code: '76',
        group_id: 2,
        id: 76,
        name: 'TerraWay',
        ogh_object_type_list: [
          {
            ogh_object_type_id: 49,
            other_improvement_object_type_list: [],
          },
          {
            ogh_object_type_id: 38,
            other_improvement_object_type_list: [],
          },
          {
            ogh_object_type_id: 40,
            other_improvement_object_type_list: [],
          },
        ],
      },
    ]);
  });
});

const dict = [
  {
    class_id: null,
    code: '76',
    group_id: 2,
    id: 76,
    name: 'TerraWay',
    ogh_object_type_list: [
      {
        ogh_object_type_id: 49,
        other_improvement_object_type_list: [],
      },
      {
        ogh_object_type_id: 38,
        other_improvement_object_type_list: [],
      },
      {
        ogh_object_type_id: 40,
        other_improvement_object_type_list: [],
      },
    ],
  },

  {
    class_id: 22,
    code: 'road_plates',
    group_id: 1,
    id: 53,
    name: 'Плиты дорожные',
    ogh_object_type_list: [
      {
        ogh_object_type_id: 1,
        other_improvement_object_type_list: [],
      },
    ],
  },
  {
    class_id: 22,
    code: 'rubber_cover',
    group_id: 1,
    id: 54,
    name: 'Резина',
    ogh_object_type_list: [
      {
        ogh_object_type_id: 1,
        other_improvement_object_type_list: [],
      },
    ],
  },
];
