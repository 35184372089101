import { resetEngineeringStructuresTab } from 'app/components/card/ogh/Odh/components/tabs/EngineeringStructures.Tab/resetEngineeringStructuresTab';

describe('ResetEngineeringStructuresTab', function () {
  it('test called changeSpy', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    const changeSpy = jest.fn();

    // Act
    resetEngineeringStructuresTab({ bridge_psc: 1 }, changeSpy);

    // Assert
    expect(changeSpy).toBeCalledWith('bridge_psc', null);
  });
});
