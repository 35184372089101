import { DOCUMENT_CLOSE_FILE_NAME } from 'app/components/card/ogh/index/card/components/CloseElectronicSignature/fields/DocumentCloseFileField';
import { formValidationCloseElectronicSignature } from 'app/components/card/ogh/index/card/components/CloseElectronicSignature/formValidation.CloseElectronicSignature';
import { SIGNATURES_NAME } from 'core/form/finalForm/fields/prepared/FileSignatureCryptoProFF';
import { SEND_REASON_NAME } from 'core/uiKit/preparedInputs/selects/multiSelect/SendReason';

describe('FormValidationCloseElectronicSignature', function () {
  it('обязательные поля не заполнены', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const errors = formValidationCloseElectronicSignature({});

    // Assert
    expect(errors).toStrictEqual({
      [SEND_REASON_NAME]: 'обязательное поле',
      [SIGNATURES_NAME]: 'Выберите сертификат для подписи и нажмите подписать',
    });
  });
  it('aобязательные поля не заполнены', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const errors = formValidationCloseElectronicSignature({
      [SEND_REASON_NAME]: [38],
    });

    // Assert
    expect(errors).toStrictEqual({
      [DOCUMENT_CLOSE_FILE_NAME]:
        'Загрузите документ-основание закрытия ОГХ. Допустимые форматы - .JPEG, .PNG, .PDF, .JPG',
      [SIGNATURES_NAME]: 'Выберите сертификат для подписи и нажмите подписать',
    });
  });
  it('1обязательные поля не заполнены', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const errors = formValidationCloseElectronicSignature({
      [SEND_REASON_NAME]: undefined,
    });

    // Assert
    expect(errors).toStrictEqual({
      [SEND_REASON_NAME]: 'обязательное поле',
      [SIGNATURES_NAME]: 'Выберите сертификат для подписи и нажмите подписать',
    });
  });
  it('Причина закрытия Объединение ОГХ. Поле ОГХ, в который входит закрываемый ОГХ не заполнено', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const errors = formValidationCloseElectronicSignature({
      [SEND_REASON_NAME]: [46],
    });

    // Assert
    expect(errors).toStrictEqual({
      id_ogh_uni: 'обязательное поле',
      signatures: 'Выберите сертификат для подписи и нажмите подписать',
    });
  });
});
