import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Ссылка столбца Id объекта .
 *
 * @param {object} props - Пропсы.
 * @param {object} props.row - Ряд.
 * @returns {JSX.Element}
 */
export const LinkShortObjectIdCell = ({ row }) => {
  return (
    <Link
      to={`/ogh/${row?.original?.object_root_id}`}
      className={'link-short-id'}
    >
      {row?.original?.short_object_id}
    </Link>
  );
};
