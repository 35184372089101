import { Map } from '@2gis/mapgl/types';
import { lineString, point, polygon } from '@turf/helpers';
import { booleanPointInPolygon, nearestPointOnLine } from '@turf/turf';
import { get as getProjection, transform } from 'ol/proj';
import { register } from 'ol/proj/proj4';
import proj4 from 'proj4';
import { v4 } from 'uuid';

import MapService, { DEFAULT_MAPPED_GEOMETRY_INSTANCES } from './MapService';
import {
  DrawGeometryObjects,
  GenericCoordinates,
  GenericRawGeometry,
  GeometryToDraw,
  GeometryTypes,
  Layers,
  MappedGeometries,
  MappedGeometryInstances,
  ResponseGeometryObjects,
  UserData,
} from './types.d';

export const ORDERED_LAYERS: Layers[] = [
  'parent',
  'selected',
  'children',
  'allChildren',
  'intersections',
  'adjacent',
  'reonArea',
  'districts',
  'copyDiff',
  'originalDiff',
];

/**
 * Нужен как TS утилка для определения типа геометрий.
 *
 * @param geometries - Объект или массив с геометриями.
 * @returns - Boolean.
 */
export const isGeometriesMapped = (
  geometries: unknown,
): geometries is MappedGeometries => !Array.isArray(geometries);

/**
 * Ключ - значение зума, значение - минимальная дистанция в пикслях.
 */
export const MINIMUM_DISTANCE_TO_CURSOR_TO_CONSIDER_AS_HOVERED = {
  16: 2.5,
  17: 3,
  18: 6,
  19: 6,
  20: 6,
  21: 15,
};

const def =
  '+proj=tmerc +ellps=bessel +towgs84=367.93,88.45,553.73,-0.8777,1.3231,2.6248,8.96 +units=m +lon_0=37.5 +lat_0=55.66666666667 +k_0=1 +x_0=0 +y_0=0';
proj4.defs('EPSG:MSK77', def);
register(proj4);

const msk77Projection = getProjection('EPSG:MSK77');
const wgs84Projection = getProjection('EPSG:4326');

/**
 * Класс со статическими методами для трансформации координат из мск77 в geoJSON и наоборот.
 */
export class GeoJSONCoordinatesFormatter {
  //

  /**
   * Преобразование точки из мск77 в geoJSON.
   *
   * @param coordinate - Точка.
   * @returns Array.
   */
  static toGeoJSON(coordinate: Array<number>) {
    return transform(coordinate, msk77Projection!, wgs84Projection!);
  }

  /**
   * Преобразование координат полигона из мск77 в geoJSON.
   *
   * @param polygons - Координаты полигона.
   * @returns Координаты полигона Array<number>[][].
   */
  static polygonToGeoJSON(polygons: Array<number>[][]) {
    return polygons.map((polygon) =>
      polygon.map((coordinates) =>
        GeoJSONCoordinatesFormatter.toGeoJSON(coordinates),
      ),
    );
  }

  /**
   * Преобразование координат полилинии из мск77 в geoJSON.
   *
   * @param polyline - Координаты полилинии.
   * @returns Координаты полилинии Array<number>[].
   */
  static polylineToGeoJSON(polyline: Array<number>[]) {
    return polyline.map((coordinates) =>
      GeoJSONCoordinatesFormatter.toGeoJSON(coordinates),
    );
  }

  /**
   * Преобразование координат точки из мск77 в geoJSON.
   *
   * @param point - Координаты точки.
   * @returns Координаты точки Array<number>.
   */
  static pointToGeoJSON(point: Array<number>) {
    return GeoJSONCoordinatesFormatter.toGeoJSON(point);
  }

  /**
   * Преобразование координат точки из geoJSON в мск77.
   *
   * @param coordinate - Координаты точки.
   * @returns Array<number, number>.
   */
  static toMsk77(coordinate: Array<number>) {
    return transform(coordinate, wgs84Projection!, msk77Projection!);
  }

  /**
   * Преобразование координат полигона из geoJSON в мск77.
   *
   * @param polygons - Координаты полигона.
   * @returns Координаты полигона Array<number>[][].
   */
  static polygonToMsk77(polygons: Array<number>[][]) {
    return polygons.map((polygon) =>
      polygon.map((coordinates) =>
        GeoJSONCoordinatesFormatter.toMsk77(coordinates),
      ),
    );
  }

  /**
   * Преобразование координат полилинии из geoJSON в мск77.
   *
   * @param polyline - Координаты полилинии.
   * @returns Координаты полилинии Array<number>[].
   */
  static polylineToMsk77(polyline: Array<number>[]) {
    return polyline.map((coordinates) =>
      GeoJSONCoordinatesFormatter.toMsk77(coordinates),
    );
  }

  /**
   * Преобразование координат точки из geoJSON в мск77.
   *
   * @param coordinates - Координаты точки.
   * @returns Координаты точки Array<number>.
   */
  static pointToMsk77(coordinates: Array<number>) {
    if (!coordinates) {
      throw new Error('coordinates is null or undefined');
    }

    const length = coordinates.length;

    if (length !== 2) {
      throw new Error(`Invalid coordinates length: ${length}`);
    }

    return GeoJSONCoordinatesFormatter.toMsk77(coordinates);
  }
}

/**
 * Создание объекта геометрии для передачи в drawGeometries.
 *
 * @param userData - Данные геометрии.
 * @returns Объект для отрисовки в drawGeometries.
 */
export const createGeometryToUpdate = <Type extends GeometryTypes>(
  userData: UserData<Type>,
): GeometryToDraw<Type> => ({
  options: {
    coordinates: userData.coordinates,
    userData,
  } as unknown as GeometryToDraw<Type>['options'],
  type: userData.type,
});

/**
 * Преобразует размеры иконки маркера в координаты на карте, где она находится;
 * это нужно, чтобы обрабатывать события ховера и клика по маркеру.
 *
 * @param {object} map - Экземпляр карты.
 * @param {object|Array<number>} geometry - Гемотрия маркера для отрисовки или координаты.
 * @param {Array<number>} size - Размер иконки.
 * @param {number} extraShiftInPixels - Дополнительный сдвиг для выравнивания иконки.
 * @returns Координаты в виде координат для полигона.
 */
export const sizeInPixelsToCoords = (
  map: Map,
  geometry: GeometryToDraw<GeometryTypes.Point> | Array<number>,
  size: [number, number] = [11, 32],
  extraShiftInPixels = 1.5,
) => {
  const coordinates =
    'options' in geometry ? geometry.options.coordinates : geometry;
  const [width, height] = size;

  const [pointXInPixels, pointYInPixels] = map.project(coordinates);
  const southWest = map.unproject([
    pointXInPixels - width + extraShiftInPixels,
    pointYInPixels,
  ]);
  const northWest = map.unproject([
    pointXInPixels - width + extraShiftInPixels,
    pointYInPixels - height + extraShiftInPixels,
  ]);
  const northEast = map.unproject([
    pointXInPixels + width + extraShiftInPixels,
    pointYInPixels - height + extraShiftInPixels,
  ]);
  const southEast = map.unproject([
    pointXInPixels + width + extraShiftInPixels,
    pointYInPixels,
  ]);

  return [[southWest, northWest, northEast, southEast, southWest]];
};

/**
 * Позвращает id для геометрии.
 *
 * @returns String.
 */
const getGeometryId = () => v4();

/**
 * Форматировать данные с геометриями для сохранения в контексте.
 *
 * @param geometry - Объект с геометриями.
 * @param layerType - Тип слоя.
 * @param mapService - Сервис карты.
 * @returns Массив объектов для передачи в updateLoadedGeometries.
 */
export const formatGeometryObjectToUpdate = (
  geometry: ResponseGeometryObjects,
  layerType: Layers,
  mapService: MapService,
) => {
  return [
    ...(geometry.polygons?.map((polygon) =>
      createGeometryToUpdate<GeometryTypes.Polygon>({
        coordinates: polygon.coordinates,
        coordinatesToCheckMouseEvent: polygon.coordinates,
        hint: geometry.hint,
        id: getGeometryId(),
        layerType: layerType || geometry.layerType,
        mapService,
        oghObjectId: geometry.id,
        type: GeometryTypes.Polygon,
        type_id: geometry.type_id,
      }),
    ) || []),
    ...(geometry.lines?.map((line) =>
      createGeometryToUpdate<GeometryTypes.Polyline>({
        coordinates: line.coordinates,
        coordinatesToCheckMouseEvent: line.coordinates,
        hint: geometry.hint,
        id: getGeometryId(),
        layerType: layerType || geometry.layerType,
        mapService,
        oghObjectId: geometry.id,
        type: GeometryTypes.Polyline,
        type_id: geometry.type_id,
      }),
    ) || []),
    ...(geometry.points?.map((point) =>
      createGeometryToUpdate<GeometryTypes.Point>({
        coordinates: point.coordinates,
        coordinatesToCheckMouseEvent: [],
        hint: geometry.hint,
        id: getGeometryId(),
        layerType: layerType || geometry.layerType,
        mapService,
        oghObjectId: geometry.id,
        type: GeometryTypes.Point,
        type_id: geometry.type_id,
      }),
    ) || []),
    ...formatGeometriesToUpdate(
      geometry.child_object || [],
      layerType,
      mapService,
    ),
  ];
};

/**
 * Форматирование объектов.
 *
 * @param geometries - Геометрии для форматирования.
 * @param layerType - Тип слоя геометрий.
 * @param mapService - Сервис карты.
 * @returns Массив объектов.
 */
export const formatGeometriesToUpdate = (
  geometries: ResponseGeometryObjects | ResponseGeometryObjects[],
  layerType: Layers,
  mapService: MapService,
): (
  | GeometryToDraw<GeometryTypes.Point>
  | GeometryToDraw<GeometryTypes.Polygon>
  | GeometryToDraw<GeometryTypes.Polyline>
)[] => {
  return Array.isArray(geometries)
    ? geometries
        .map((geometry: ResponseGeometryObjects) =>
          formatGeometryObjectToUpdate(geometry, layerType, mapService),
        )
        .flat(1)
    : formatGeometryObjectToUpdate(geometries, layerType, mapService);
};

/**
 * Форматирует массив геометрий в объект
 * geometries[] --> { point: [], polyline: [], polygon: []}
 * для последующей отрисовки:
 * polygon[] -> polyline[] -> point[].
 *
 * @param geometries - Массив с геометриями.
 * @returns {object}
 */
export const mapGeometries = (
  geometries: DrawGeometryObjects,
): MappedGeometries => {
  return isGeometriesMapped(geometries)
    ? geometries
    : Array.isArray(geometries)
    ? (
        geometries as (
          | GeometryToDraw<GeometryTypes.Point>
          | GeometryToDraw<GeometryTypes.Polygon>
          | GeometryToDraw<GeometryTypes.Polyline>
        )[]
      ).reduce(
        (acc: MappedGeometries, geometry) => {
          if (geometry.type === GeometryTypes.Polyline) {
            acc[geometry.type].push(geometry);
          } else if (geometry.type === GeometryTypes.Polygon) {
            acc[geometry.type].push(geometry);
          } else {
            acc[geometry.type].push(geometry);
          }

          return acc;
        },
        {
          [GeometryTypes.Point]: [],
          [GeometryTypes.Polyline]: [],
          [GeometryTypes.Polygon]: [],
        } as MappedGeometries,
      )
    : geometries;
};

export const defaultMappedGeometries: MappedGeometries = {
  [GeometryTypes.Polygon]: [],
  [GeometryTypes.Polyline]: [],
  [GeometryTypes.Point]: [],
};

export const defaultMappedGeometryInstances: MappedGeometryInstances = {
  [GeometryTypes.Polygon]: [],
  [GeometryTypes.Polyline]: [],
  [GeometryTypes.Point]: [],
};

export const DEFAULT_LAYER_INSTANCES = ORDERED_LAYERS.reduce(
  (acc, layer) => ({
    ...acc,
    [layer]: DEFAULT_MAPPED_GEOMETRY_INSTANCES,
  }),
  {} as Record<Layers, MappedGeometryInstances>,
);

export const DEFAULT_MAPPED_GEOMETRIES = ORDERED_LAYERS.reduce(
  (acc, layer) => ({
    ...acc,
    [layer]: DEFAULT_MAPPED_GEOMETRY_INSTANCES,
  }),
  {} as Record<Layers, MappedGeometries>,
);

/**
 * Утилка для работы с геометриями.
 */
export class GeometryUtils {
  //

  /**
   * Конструктор.
   *
   * @param {object} mapService - Экземпляр MapService.
   */
  constructor(private mapService: MapService) {}

  /**
   * Возвращает экземпляр MapService.
   *
   * @returns MapService.
   */
  getMapService() {
    return this.mapService;
  }

  /**
   * Устанавливает экземпляр MapService.
   *
   * @param {object} mapService - Экземпляр MapService.
   */
  setMapService(mapService: MapService) {
    this.mapService = mapService;
  }

  /**
   * Возвращает координаты на карте в пикселях.
   *
   * @param {object} map - Экземпляр Map.
   * @param {Array<number>} coordinates - Координаты.
   * @returns Array<number>.
   */
  static coordinatesToPixels(
    map: Map,
    coordinates: GenericCoordinates<GeometryTypes.Point>,
  ) {
    return map.project(coordinates) || [0, 0];
  }

  /**
   * Дистанция между точками на карте в пикселях.
   *
   * @param {object} map - Экземпляр Map.
   * @param {Array<number>} aPoint - Координаты.
   * @param {Array<number>} bPoint - Координаты.
   * @returns Number.
   */
  static distanceBetweenPointsInPixels(
    map: Map,
    aPoint: GenericCoordinates<GeometryTypes.Point>,
    bPoint: GenericCoordinates<GeometryTypes.Point>,
  ) {
    const [x1InPixels, y1InPixels] = GeometryUtils.coordinatesToPixels(
      map,
      aPoint,
    );
    const [x2InPixels, y2InPixels] = GeometryUtils.coordinatesToPixels(
      map,
      bPoint,
    );

    // Теорема Пифагора
    // https://stackoverflow.com/questions/20916953/get-distance-between-two-points-in-canvas
    // (a^2 + b^2)^2
    return Math.sqrt(
      (x1InPixels - x2InPixels) ** 2 + (y1InPixels - y2InPixels) ** 2,
    );
  }

  /**
   * Определяет находится ли точка на линии.
   *
   * @param {object} map - Экземпляр Map.
   * @param {Array<number>} coordinates - Координаты.
   * @param {Array<Array<number>>} lineCoordinates - Координаты.
   * @returns Boolean.
   */
  static isPointOnLine(
    map: Map,
    coordinates: GenericCoordinates<GeometryTypes.Point>,
    lineCoordinates: GenericCoordinates<GeometryTypes.Polyline>,
  ) {
    const pointFeature = point(coordinates);
    const lineFeature = lineString(lineCoordinates);

    const nearestPointToCursorOnLine = nearestPointOnLine(
      lineFeature,
      pointFeature,
    );
    const distanceBetweenPoints = GeometryUtils.distanceBetweenPointsInPixels(
      map,
      nearestPointToCursorOnLine.geometry.coordinates,
      pointFeature.geometry.coordinates,
    );

    // минимальный ключ зума 16, максимальный 20
    const zoomKey = Math.min(
      Math.max(Math.round(map.getZoom()) || 16, 16),
      20,
    ) as keyof typeof MINIMUM_DISTANCE_TO_CURSOR_TO_CONSIDER_AS_HOVERED;
    return (
      distanceBetweenPoints <=
      MINIMUM_DISTANCE_TO_CURSOR_TO_CONSIDER_AS_HOVERED[zoomKey]
    );
  }

  /**
   * Определяет находится ли точка внутри полигона.
   *
   * @param {Array<number>} coordinates - Координаты.
   * @param {Array<Array<Array<number>>>} polygonCoordinates - Координаты.
   * @returns Boolean.
   */
  static isPointWithinPolygon(
    coordinates: GenericCoordinates<GeometryTypes.Point>,
    polygonCoordinates: GenericCoordinates<GeometryTypes.Polygon>,
  ) {
    const pointFeature = point(coordinates);
    const polygonFeature = polygon(polygonCoordinates);
    return booleanPointInPolygon(pointFeature, polygonFeature);
  }

  /**
   * Координаты на карте в пикселях.
   *
   * @param {Array<number>} coordinates - Координаты.
   * @returns Array<number>.
   */
  coordinatesToPixels(coordinates: GenericCoordinates<GeometryTypes.Point>) {
    return GeometryUtils.coordinatesToPixels(this.mapService.map, coordinates);
  }

  /**
   * Определяет находится ли точка на линии.
   *
   * @param {Array<number>} coordinates - Координаты.
   * @param {Array<Array<number>>} lineCoordinates - Координаты.
   * @returns Boolean.
   */
  isPointOnLine(
    coordinates: GenericCoordinates<GeometryTypes.Point>,
    lineCoordinates: GenericCoordinates<GeometryTypes.Polyline>,
  ) {
    return GeometryUtils.isPointOnLine(
      this.mapService.map,
      coordinates,
      lineCoordinates,
    );
  }

  /**
   * Определяет находится ли точка внутри полигона.
   *
   * @param {Array<number>} coordinates - Координаты.
   * @param {Array<Array<Array<number>>>} polygonCoordinates - Координаты.
   * @returns Boolean.
   */
  isPointWithinPolygon(
    coordinates: GenericCoordinates<GeometryTypes.Point>,
    polygonCoordinates: GenericCoordinates<GeometryTypes.Polygon>,
  ) {
    return GeometryUtils.isPointWithinPolygon(coordinates, polygonCoordinates);
  }
}

/**
 * Получает геометрии для сохранения.
 *
 * @param mapService - Сервис карты.
 * @param newGeometries - Новые геометрии.
 * @param type - Тип геометрий.
 * @param currentChildrenLoadedGeometries - Текущие загруженные дочерние геометрии.
 * @param currentParentLoadedGeometries - Текущие загруженные родительские геометрии.
 * @param changinLayerType - Тип изменяемого слоя.
 * @returns Object.
 */
export const getGeometriesToSave = <
  Type extends Exclude<GeometryTypes, GeometryTypes.Hole>,
>(
  mapService: MapService,
  newGeometries: GenericRawGeometry<Type>[],
  type: Type,
  currentChildrenLoadedGeometries: MappedGeometries,
  currentParentLoadedGeometries: MappedGeometries,
  changinLayerType: Layers,
) => {
  let childPoints = currentChildrenLoadedGeometries.point;
  let childPolylines = currentChildrenLoadedGeometries.polyline;
  let childPolygons = currentChildrenLoadedGeometries.polygon;

  if (changinLayerType === 'children') {
    childPoints = newGeometries
      .filter(
        (geometry) =>
          !currentChildrenLoadedGeometries.point.find(
            (point) => point.options.userData.id === geometry.userData.id,
          ),
      )
      .filter((geometry) => geometry.userData.type === GeometryTypes.Point)
      .map((geometry) => {
        const currentGeometry = currentChildrenLoadedGeometries.point.find(
          (point) => point.options.userData.id === geometry.userData.id,
        );

        return (
          currentGeometry ||
          ({
            options: {
              coordinates: geometry.userData.coordinates as number[],
              mapService: geometry.userData.mapService,
              userData: geometry.userData as UserData<GeometryTypes.Point>,
            },
            type: GeometryTypes.Point,
          } as GeometryToDraw<GeometryTypes.Point>)
        );
      });

    childPolylines = currentChildrenLoadedGeometries.polyline.filter((child) =>
      newGeometries.find(
        (geometry) => geometry.userData.id === child.options.userData.id,
      ),
    );
    childPolygons = currentChildrenLoadedGeometries.polygon.filter((child) => {
      const curInNew = newGeometries.find((geometry) => {
        return geometry.userData.id === child.options.userData.id;
      });
      return !!curInNew;
    });
  }

  let parentPoints = currentParentLoadedGeometries.point;
  let parentPolylines = currentParentLoadedGeometries.polyline;
  let parentPolygons = currentParentLoadedGeometries.polygon;

  if (changinLayerType === 'parent') {
    parentPoints = currentParentLoadedGeometries.point.filter((child) =>
      newGeometries.find(
        (geometry) => geometry.userData.id === child.options.userData.id,
      ),
    );
    parentPolylines = currentParentLoadedGeometries.polyline.filter((child) =>
      newGeometries.find(
        (geometry) => geometry.userData.id === child.options.userData.id,
      ),
    );
    parentPolygons = currentParentLoadedGeometries.polygon.filter((child) => {
      const curInNew = newGeometries.find((geometry) => {
        return geometry.userData.id === child.options.userData.id;
      });
      return !!curInNew;
    });
  }

  /**
   * Фильтрует удаленные геометрии.
   *
   * @param layerType - Тип слоя.
   * @param type - Тип геометрии.
   * @returns Array.
   */
  const filterDeleted = (
    layerType: Layers,
    type: Exclude<GeometryTypes, GeometryTypes.Hole>,
  ) =>
    mapService.geometriesData[layerType][type].filter((point) =>
      newGeometries.find(
        (newGeometry) => newGeometry.userData.id === point.options.userData.id,
      ),
    );

  const currentGeometriesByType = {
    ['parent' as Layers]: {
      [GeometryTypes.Point]: filterDeleted('parent', GeometryTypes.Point),
      [GeometryTypes.Polyline]: filterDeleted('parent', GeometryTypes.Polyline),
      [GeometryTypes.Polygon]: filterDeleted('parent', GeometryTypes.Polygon),
    },
    ['children' as Layers]: {
      [GeometryTypes.Point]: filterDeleted('children', GeometryTypes.Point),
      [GeometryTypes.Polyline]: filterDeleted(
        'children',
        GeometryTypes.Polyline,
      ),
      [GeometryTypes.Polygon]: filterDeleted('children', GeometryTypes.Polygon),
    },
  };

  const { parent, children } = newGeometries.reduce(
    (acc, geometry) => {
      const changedCoordinates = geometry.userData.coordinates;
      const { layerType } = geometry.userData;

      if (layerType !== 'parent' && layerType !== 'children') {
        return acc;
      }

      const geometries =
        currentGeometriesByType[geometry.userData.layerType][type];
      const currentGeometryIndex = geometries.findIndex(
        (_geometry) => _geometry.options.userData.id === geometry.userData.id,
      );

      const currentGeometry =
        currentGeometryIndex !== -1 ? geometries[currentGeometryIndex] : null;

      if (currentGeometry) {
        // @ts-ignore
        acc[layerType].splice(currentGeometryIndex, 1, {
          ...currentGeometry,
          // @ts-ignore
          options: {
            ...currentGeometry.options,
            coordinates: changedCoordinates,
            userData: {
              ...currentGeometry.options.userData,
              ...geometry.userData,
            },
          },
        });
      } else {
        // @ts-ignore
        acc[layerType].splice(currentGeometryIndex, 1);
      }

      return acc;
    },
    {
      children: [
        ...(type === GeometryTypes.Point
          ? childPoints
          : type === GeometryTypes.Polygon
          ? childPolygons
          : type === GeometryTypes.Polyline
          ? childPolylines
          : []),
      ],
      parent: [
        ...(type === GeometryTypes.Point
          ? parentPoints
          : type === GeometryTypes.Polygon
          ? parentPolygons
          : type === GeometryTypes.Polyline
          ? parentPolylines
          : []),
      ],
    } as {
      parent: GeometryToDraw<Type>[];
      children: GeometryToDraw<Type>[];
    },
  );

  return { children, parent };
};
