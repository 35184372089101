// import { WithMapContext } from 'app/components/map/withMap';
import { clearCurrentCardCash } from 'app/api/hooks/useGetCurrentCard/clearCurrentCardCash';
import { prepareActionsTree } from 'app/components/card/ogh/index/card/utils/prepareActionsTree';
import { onSave } from 'app/utils/card/checkGeometry';
import {
  RANDOM_ID,
  RANDOM_NUMBER,
  RANDOM_WORD,
} from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import React from 'react';
import { vi } from 'vitest';

import LeftPanel from '../LeftPanel';
import IndexCard from './IndexCard';

vi.mock('app/utils/card/checkGeometry');
vi.mock('app/api/hooks/useGetCurrentCard/clearCurrentCardCash');
vi.mock('app/components/card/ogh/index/card/utils/prepareActionsTree');
vi.mock('config/env', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    SERVIS_MAP: 'dtw',
  };
});

vi.useFakeTimers();

describe('🐛 spec IndexCard DTW', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    const props = {
      Map: vi.fn(),
      card: {
        type_id: RANDOM_ID,
      },
      location: { pathname: '' },
    };

    // 🧹 clear mock

    //🔥 Act
    const wrapper = shallow(<IndexCard {...props} />);

    //❓ Assert
    expect(wrapper.text()).toBe(
      '<LeftPanelDefault /><RightPanel /><DialogAlert /><DialogAlert /><DialogAction />',
    );
  });

  it('🧪 onSave DTW', async () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    const sendUpdateCardSpy = vi.fn().mockResolvedValue(123);
    const updateTreeSpy = vi.fn();
    const MapSpy = vi.fn();
    const fetchActionWithGeometryDTWSpy = vi.fn();

    const props = {
      Map: MapSpy,
      actionsTree: RANDOM_WORD,
      allowedWithoutGeometry: true,
      card: {
        record_id: RANDOM_ID,
        type_id: RANDOM_NUMBER,
      },
      fetchActionWithGeometryDTW: fetchActionWithGeometryDTWSpy,
      hasGeometry: true,
      location: { pathname: '' },
      mapContext: {
        getGeometry: vi.fn(() => 'getGeometry'),
        getGeometryToJson: vi.fn(() => 'getGeometryToJson'),
        getRawGeometry: vi.fn(),
      },
      parentInfo: {},
      sendUpdateCard: sendUpdateCardSpy,
      treeJournal: { cnt_action: true },
      updateTree: updateTreeSpy,
    };

    onSave.mockImplementation(
      (data, props, draw, showIgnoreIntersectionAlert, saveObject) => {
        saveObject();
      },
    );
    prepareActionsTree.mockResolvedValue({});

    //🔥 Act
    const wrapper = shallow(<IndexCard {...props} />);
    const { updateObject } = wrapper.find(LeftPanel).props();
    updateObject({ foo: 123 });
    await vi.runOnlyPendingTimersAsync();

    //❓ Assert
    // Проверяем, что функция была вызвана
    expect(onSave).toHaveBeenCalled();

    // Извлекаем первый v второй аргумент из первого вызова
    const [firstArg, secondArg] = onSave.mock.calls[0];
    expect(firstArg).toStrictEqual({
      attribute: undefined,
      cnt: true,
      geometry: 'getGeometryToJson',
      id: undefined,
      journal: undefined,
      parentId: undefined,
      startDate: undefined,
      treeParentId: null,
      typeId: undefined,
    });
    expect(secondArg).toStrictEqual(props);

    expect(prepareActionsTree).toHaveBeenCalledWith(RANDOM_WORD);
    expect(clearCurrentCardCash).toHaveBeenCalledTimes(1);
    expect(updateTreeSpy).toHaveBeenCalledTimes(1);
  });

  it('🧪 deleteObject', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    // mockDefault();

    // 🧹 clear mock
    const sendDeleteCardSpy = vi.fn();
    const props = {
      Map: vi.fn(),
      card: {
        type_id: RANDOM_ID,
      },
      location: { pathname: '' },
      parentTypeId: '🆎 parentTypeId',
      sendDeleteCard: sendDeleteCardSpy,
    };

    //🔥 Act
    const wrapper = shallow(<IndexCard {...props} />);
    const deleteObject = wrapper.find('LeftPanelDefault').prop('deleteOgh');
    deleteObject('🔛 params');

    //❓ Assert
    expect(sendDeleteCardSpy).toHaveBeenCalledWith(
      '🔛 params',
      '🆎 parentTypeId',
    );
  });
});
