import { useFetchOghDispatch } from 'app/actions/odsObjectActions/useFetchOgh.Dispatch';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { queryClient } from 'app/routes/QueryClientProvider';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import { shallow } from 'enzyme';
import { useState } from 'react';
import { vi } from 'vitest';

import { DialogReject } from './Dialog.Reject';
import { RejectExternalObject } from './RejectExternalObject';
import { RejectExternalObjectOghButton } from './RejectExternalObjectOgh.Button';

vi.mock('core/utils/hooks/useGetObjectId');
vi.mock('app/actions/odsObjectActions/useFetchOgh.Dispatch');
vi.mock('app/routes/QueryClientProvider');
vi.mock('app/pages/cardsOgh/hooks/useEditMode');
vi.mock('react', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    useState: vi.fn(),
  };
});

describe('file RejectExternalObjectComponent.spec', () => {
  describe('show', () => {
    // useState.mockImplementation(() => [
    //   'someMockedValue',
    //   vi.fn().mockReturnValue([1, 3]),
    // ]);
    it('simple нет нихто', () => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      useGetObjectId.mockReturnValue({});
      useEditMode.mockReturnValue({
        editMode: false,
      });
      useState.mockReturnValue([]);

      // Act
      const wrapper = shallow(<RejectExternalObject />);

      // Assert
      expect(wrapper.text()).toBe('');
    });

    it('есть editMode есть objectId', () => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      useEditMode.mockReturnValue({
        editMode: true,
      });
      useFetchOghDispatch.mockReturnValue({});
      useGetObjectId.mockReturnValue({ objectId: 23 });
      useState.mockReturnValue([]);

      // Act
      const wrapper = shallow(<RejectExternalObject />);

      // Assert
      expect(wrapper.text()).toBe('');
    });

    it('нет editMode нет objectId', () => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      useEditMode.mockReturnValue({
        editMode: false,
      });
      useGetObjectId.mockReturnValue({ objectId: 23 });
      useFetchOghDispatch.mockReturnValue({});
      useState.mockReturnValue([]);

      // Act
      const wrapper = shallow(<RejectExternalObject />);
      wrapper.text();

      // Assert
      expect(wrapper.text()).toBe(
        '<RejectExternalObjectOghButton /><DialogReject />',
      );
    });
  });

  describe('>> actions', () => {
    it('> setOpen', () => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      useEditMode.mockReturnValue({
        editMode: false,
      });
      useGetObjectId.mockReturnValue({ objectId: 23 });
      useFetchOghDispatch.mockReturnValue({});
      const setOpen = vi.fn();
      useState.mockReturnValue([1, setOpen]);
      const wrapper = shallow(<RejectExternalObject />);

      // Assert
      expect(setOpen).toHaveBeenCalledTimes(0);

      // Act
      wrapper.find(RejectExternalObjectOghButton).props().onClick();
      wrapper.find(RejectExternalObjectOghButton).simulate('click');

      // Assert
      expect(setOpen).toHaveBeenCalledTimes(2);
    });

    it('> onClose', () => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      useEditMode.mockReturnValue({
        editMode: false,
      });
      useGetObjectId.mockReturnValue({ objectId: 23 });
      const fetchOgh = vi.fn();
      useFetchOghDispatch.mockReturnValue(fetchOgh);
      const setOpen = vi.fn();
      useState.mockReturnValue([1, setOpen]);
      const wrapper = shallow(<RejectExternalObject />);
      const invalidateQueriesSpy = vi.spyOn(queryClient, 'invalidateQueries');

      // Assert
      expect(fetchOgh).toHaveBeenCalledTimes(0);
      expect(setOpen).toHaveBeenCalledTimes(0);
      expect(invalidateQueriesSpy).toHaveBeenCalledTimes(0);

      // Act
      wrapper.find(DialogReject).props().onClose();

      // Assert
      expect(setOpen).toHaveBeenCalledWith(false);
      expect(fetchOgh).toHaveBeenCalledTimes(1);
      expect(invalidateQueriesSpy).toHaveBeenCalledWith({
        queryKey: ['getVisibilityButtonsOgh'],
      });
    });
  });
});
