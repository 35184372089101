import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';
import { SelectPreparedProps } from 'types/inputs';

export const ABUTMENT_TYPE_ID_NAME = 'abutment_type_id';

export interface IAbutmentTypeData {
  id: number;
  name: string;
  parent_ogh_object_type_list: number[];
}

export interface IAbutmentTypeProps extends SelectPreparedProps {
  filter?: (data: IAbutmentTypeData[]) => IAbutmentTypeData[];
}

/**
 * __Тип элемента сопряжения__.
 *
 * @param props - Пропсы .
 * @returns JSX.Element.
 */
export const AbutmentTypeName = (props: IAbutmentTypeProps) => {
  const dict = 'abutment_type';
  const { data = [] } = useGetDictionary<IAbutmentTypeData[]>(dict);

  return (
    <Select
      {...props}
      options={data}
      label={'Тип элемента сопряжения'}
      name={ABUTMENT_TYPE_ID_NAME}
    />
  );
};
