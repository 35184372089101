import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { CleaningTypeField } from 'app/components/card/ogh/components/fields/selectFields/CleaningType.Field/CleaningType.Field';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { RANDOM_NUMBER } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

vi.mock('app/api/hooks/useGetCurrentCard');
vi.mock('app/api/hooks/useIsEditCurrentObjectIsExternalSystem');
vi.mock('app/pages/cardsOgh/hooks/useMode');

describe('🐛 CleaningTypeFieldSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useMode.mockReturnValue({ editMode: true });
    useIsEditCurrentObjectIsExternalSystem.mockReturnValue(true);
    useGetCurrentCard.mockReturnValue({ typeId: RANDOM_NUMBER });

    // 🔥 Act

    const wrapper = shallow(<CleaningTypeField />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<CleaningTypeRF />');
  });
});
