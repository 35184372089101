import { AdditionalInformationTab } from 'app/components/card/ogh/ImprovementTerritoryCard/components/AdditionalInformation.Tab/AdditionalInformation.Tab';
import { OznTypeField } from 'app/components/card/ogh/ImprovementTerritoryCard/components/OznTypeField';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { PhoneFieldRF } from 'core/form/reduxForm/fields/default/PhoneField.RF';
import { TextFieldRF } from 'core/form/reduxForm/fields/default/TextFieldRF';
import { RANDOM_BOOL } from 'core/forTesting/constants';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { shallow } from 'enzyme';
import React from 'react';
import { vi } from 'vitest';

vi.mock('app/pages/cardsOgh/hooks/useMode');

describe('🐛 AdditionalInformationTabSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useMode.mockReturnValue({ editMode: RANDOM_BOOL });

    // 🔥 Act

    const wrapper = shallow(<AdditionalInformationTab />);

    // ❓ Assert
    expect(wrapper.props()).toStrictEqual({
      children: (
        <FieldsSplitColumns>
          <OznTypeField disabled={!RANDOM_BOOL} />
          <TextFieldRF
            label={'Описание месторасположения'}
            name={'place_descr'}
            disabled={!RANDOM_BOOL}
          />
          <TextFieldRF
            label={'ИНН балансодержателя'}
            name={'owner_inn'}
            disabled
          />
          <TextFieldRF
            label={'КПП балансодержателя'}
            name={'owner_kpp'}
            disabled
          />
          <TextFieldRF
            label={'ОГРН балансодержателя'}
            name={'owner_ogrn'}
            disabled
          />
          <PhoneFieldRF
            label={'Телефон балансодержателя'}
            name={'owner_phone'}
            disabled={!RANDOM_BOOL}
          />
          <TextFieldRF
            label={'Сайт балансодержателя'}
            name={'owner_link'}
            disabled={!RANDOM_BOOL}
          />
          <TextFieldRF
            label={'Парк по месту жительства'}
            name={'park_descr'}
            disabled={!RANDOM_BOOL}
          />
          <TextFieldRF
            label={'Сайт парковой территории'}
            name={'park_link'}
            disabled={!RANDOM_BOOL}
          />
          <PhoneFieldRF
            label={'Телефон парковой территории'}
            name={'park_phone'}
            disabled={!RANDOM_BOOL}
          />
          <TextFieldRF
            label={'Полное наименование эксплуатирующей организации'}
            name={'oper_fullname'}
            disabled={!RANDOM_BOOL}
          />
          <PhoneFieldRF
            label={'Телефон эксплуатирующей организации'}
            name={'oper_phone'}
            disabled={!RANDOM_BOOL}
          />
          <TextFieldRF
            label={'Сайт эксплуатирующей организации'}
            name={'oper_link'}
            disabled={!RANDOM_BOOL}
          />
          <TextFieldRF
            label={'График работы'}
            name={'schedule'}
            disabled={!RANDOM_BOOL}
          />
          <TextFieldRF
            label={'День недели'}
            name={'schedule_days'}
            disabled={!RANDOM_BOOL}
          />
          <TextFieldRF
            label={'Часы работы'}
            name={'schedule_hours'}
            disabled={!RANDOM_BOOL}
          />
          <TextFieldRF
            label={'Уточнение графика работы'}
            name={'schedule_comm'}
            disabled={!RANDOM_BOOL}
          />
          <TextFieldRF
            label={'Способ геопривязки парковой территории'}
            name={'park_geo'}
            disabled={!RANDOM_BOOL}
          />
          <TextFieldRF
            label={'Дополнительная информация о входе на территорию'}
            disabled={!RANDOM_BOOL}
            name={'add_inform'}
          />
        </FieldsSplitColumns>
      ),
      className: 'mb-3',
      hidden: true,
      id: 'additional-information',
    });
  });
});
