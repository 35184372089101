import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import { IsDiffHeightMarkField } from './IsDiffHeightMark.Field';

vi.mock('app/pages/cardsOgh/hooks/useMode');
vi.mock('app/api/hooks/useIsEditCurrentObjectIsExternalSystem');

describe('🐛 IsDiffHeightMarkFieldSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useMode.mockReturnValue({ editMode: true });
    useIsEditCurrentObjectIsExternalSystem.mockReturnValue(true);

    // 🔥 Act

    const wrapper = shallow(<IsDiffHeightMarkField />);

    // ❓ Assert
    expect(wrapper.props()).toStrictEqual({
      disabled: false,
    });
  });
});
