import { columnsLandscapingTable } from 'app/components/card/ogh/ImprovementTerritoryCard/components/Landscaping.Tab/Landscaping.Table/columns.Landscaping.Table';
import { useGetDataLandscapingTable } from 'app/components/card/ogh/ImprovementTerritoryCard/components/Landscaping.Tab/Landscaping.Table/useGetDataLandscaping.Table';
import { TableSimple } from 'core/uiKit/components/tables/TableSimple';
import { shallow } from 'enzyme';
import React from 'react';
import { vi } from 'vitest';

import { LandscapingTable } from './Landscaping.Table';

vi.mock('./useGetDataLandscaping.Table');

describe('LandscapingTableSpec', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useGetDataLandscapingTable.mockReturnValue([]);

    // 🔥 Act

    const wrapper = shallow(<LandscapingTable />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<GroupContainer />');
  });
  it('CardContainer props', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useGetDataLandscapingTable.mockReturnValue([]);

    // 🔥 Act

    const wrapper = shallow(<LandscapingTable />);

    // ❓ Assert
    expect(wrapper.props()).toStrictEqual({
      children: <TableSimple columns={columnsLandscapingTable} data={[]} />,
      title: 'Благоустройство',
    });
  });
});
