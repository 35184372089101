import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { vi } from 'vitest';

import { useSetupContainerTypeIdFieldContainer } from './useSetup.ContainerTypeId.Field.Container';

vi.mock('app/api/hooks/useGetCurrentCard');
vi.mock('app/api/hooks/useGetParentCard');
vi.mock('app/api/hooks/useIsEditCurrentObjectIsExternalSystem');
vi.mock('app/pages/cardsOgh/hooks/useMode');

/**
 * Mock Default.
 *
 * @returns {void}
 */
const mockDefault = () => {
  useGetCurrentCard.mockReturnValue({});
  useGetParentCard.mockReturnValue({});
  useMode.mockReturnValue({});
};

describe('🐛 spec useSetup.ContainerTypeId.Field.Container', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    mockDefault();

    // 🧹 clear mock

    //🔥 Act
    const res = useSetupContainerTypeIdFieldContainer();

    //❓ Assert
    expect(res).toStrictEqual({
      filter: expect.any(Function),
      isEdit: undefined,
    });
  });

  it.each`
    editMode | rootId       | isEditCurrentObjectIsExternalSystem | expected
    ${true}  | ${undefined} | ${false}                            | ${false}
    ${true}  | ${undefined} | ${true}                             | ${true}
    ${true}  | ${2}         | ${false}                            | ${false}
    ${true}  | ${3}         | ${true}                             | ${false}
    ${false} | ${undefined} | ${false}                            | ${false}
    ${false} | ${undefined} | ${true}                             | ${false}
    ${false} | ${2}         | ${false}                            | ${false}
    ${false} | ${3}         | ${true}                             | ${false}
  `(
    '🧪 isEdit editMode=$editMode rootId=$rootId isEditCurrentObjectIsExternalSystem=$isEditCurrentObjectIsExternalSystem expected: $expected',
    ({ editMode, rootId, isEditCurrentObjectIsExternalSystem, expected }) => {
      expect.hasAssertions();
      // ☣️  Arrange (всякие моки)
      mockDefault();
      useMode.mockReturnValue({ editMode });
      useGetCurrentCard.mockReturnValue({ root_id: rootId });
      useIsEditCurrentObjectIsExternalSystem.mockReturnValue(
        isEditCurrentObjectIsExternalSystem,
      );

      // 🧹 clear mock

      //🔥 Act
      const { isEdit } = useSetupContainerTypeIdFieldContainer();

      //❓ Assert
      expect(isEdit).toBe(expected);
    },
  );

  it('🧪 filter', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    mockDefault();
    useGetParentCard.mockReturnValue({
      type_id: 40,
    });

    // 🧹 clear mock

    //🔥 Act
    const { filter } = useSetupContainerTypeIdFieldContainer();

    const res = filter([
      {
        attribute: {
          code: 'bunker_8m',
          has_separate_garbage_collection: false,
          migr_code: [],
          name: 'Бункер накопитель 8 куб.м',
          parent_ogh_object_type_list: [40],
        },
        id: 5,
      },
      {
        attribute: {
          code: 'bunker_area',
          has_separate_garbage_collection: false,
          migr_code: ['BUNKER_SITE'],
          name: 'Бункерная площадка',
          parent_ogh_object_type_list: [38],
        },
        id: 5545526,
      },
      {
        attribute: {
          code: 'additional_container_rso',
          has_separate_garbage_collection: false,
          migr_code: [],
          name: 'Дополнительный контейнер-сетка для РСО',
          parent_ogh_object_type_list: [40],
        },
        id: 7,
      },
    ]);

    //❓ Assert
    expect(res).toStrictEqual([
      {
        attribute: {
          code: 'bunker_8m',
          has_separate_garbage_collection: false,
          migr_code: [],
          name: 'Бункер накопитель 8 куб.м',
          parent_ogh_object_type_list: [40],
        },
        id: 5,
      },
      {
        attribute: {
          code: 'additional_container_rso',
          has_separate_garbage_collection: false,
          migr_code: [],
          name: 'Дополнительный контейнер-сетка для РСО',
          parent_ogh_object_type_list: [40],
        },
        id: 7,
      },
    ]);
  });
});
