import { cancelEditCard } from 'app/actions/odsObjectActions';
import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { RANDOM_ID, RANDOM_WORD } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { useDispatch } from 'react-redux';
import { vi } from 'vitest';

import { CancelButtonHOC } from './CancelButtonHOC';

vi.mock('app/api/hooks/useGetCurrentCard');
vi.mock('react-redux', () => ({
  ...vi.importActual('react-redux'),
  connect: vi.fn(() => (value) => {
    return value;
  }),
  useDispatch: vi.fn(),
}));
vi.mock('app/actions/odsObjectActions');

describe('CancelButtonHocSpec', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    cancelEditCard.mockReturnValue(RANDOM_WORD);
    useGetCurrentCard.mockReturnValue({});

    // 🔥 Act

    const wrapper = shallow(<CancelButtonHOC />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<CancelButton />');
  });
  it('resetForm', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const dispatchSpy = vi.fn();
    cancelEditCard.mockReturnValue(RANDOM_WORD);
    useDispatch.mockReturnValue(dispatchSpy);

    useGetCurrentCard.mockReturnValue({
      record_id: RANDOM_ID,
      root_id: RANDOM_ID,
    });

    // 🔥 Act

    const wrapper = shallow(<CancelButtonHOC />);
    const { resetForm } = wrapper.props();
    resetForm();
    resetForm();

    // ❓ Assert
    expect(dispatchSpy).toHaveBeenCalledWith(RANDOM_WORD);
    expect(cancelEditCard).toHaveBeenCalledWith(RANDOM_ID, RANDOM_ID);
  });
  it('resetForm currentCard {}', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const dispatchSpy = vi.fn();
    cancelEditCard.mockReturnValue(RANDOM_WORD);
    useDispatch.mockReturnValue(dispatchSpy);

    useGetCurrentCard.mockReturnValue({});

    // 🔥 Act

    const wrapper = shallow(<CancelButtonHOC currentCard={{}} />);
    const { resetForm } = wrapper.props();
    resetForm();
    resetForm();

    // ❓ Assert
    expect(dispatchSpy).toHaveBeenCalledWith(RANDOM_WORD);
    expect(cancelEditCard).toHaveBeenCalledWith(undefined, undefined);
  });
});
