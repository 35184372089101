import { RANDOM_NUMBER } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import TechPlaceCard, { onSubmit } from './tech_place';

vi.mock('app/components/card/common/validation');
vi.mock('./submit');
vi.mock('redux-form', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    reduxForm: vi.fn(() => (Component) => {
      return Component;
    }),
  };
});

vi.mock('react-redux', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    connect: vi.fn(() => (ba) => {
      return ba;
    }),
  };
});

const defaultProps = {
  card: {
    address_list: [
      {
        address_id: '2',
        attributes: null,
        description: 'Test Address',
        okrug_id: '1',
        street_id: '3',
      },
    ],
    buildings_type: [{ id: 1, name: 'Type 1' }],
    end_date: '2023-12-31',
    is_diff_height_mark: false,
    material: [{ id: 1, name: 'Material 1' }],
    no_calc: false,
    parent_info: 'Parent Info',
    root_id: 12345,
    start_date: '2023-01-01',
    total_area_geo: '100.00',
    type_id: 28,
  },
  editMode: false,
  isEditCurrentObjectIsExternalSystem: false,
  mode: {},
  required: false,
};

describe('🐛 TechPlaceCard', () => {
  it('🧪 default', () => {
    expect.hasAssertions();

    //☣️ Arrange (всякие моки)
    const props = {
      card: { bord_begin: 1, flat_element_type: [], type_id: RANDOM_NUMBER },
      card_type: 'TECH_PLACE',
      editMode: true,
      formValues: {
        bord_begin: 1,
      },
      isCurrentObjectIsExternalSystem: false,

      mode: {},
    };

    //🔥 Act
    const wrapper = shallow(<TechPlaceCard {...props} />);

    //❓ Assert
    expect(wrapper.text()).toBe('<CardContainer />');
  });

  it('🧪 onSubmit', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    const props = { card: { type_id: 31 } };
    const onSubmitProps = onSubmit.bind({ props });

    //🔥 Act
    onSubmitProps();

    //❓ Assert
    expect(1).toBe(1);
  });

  it('🧪 рендерит компоненты с пропсами', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)

    //🔥 Act
    const wrapper = shallow(<TechPlaceCard {...defaultProps} />);

    const areaField = wrapper.find('AreaField');
    const noCalcRF = wrapper.find('NoCalcRF');
    const parentInfo = wrapper.find('ParentInfo');
    const isDiffHeightMarkField = wrapper.find('IsDiffHeightMarkField');
    const otherDocumentsRF = wrapper.find('OtherDocumentsRF');
    const tabs = wrapper.find('Tabs');
    const cardAbstract = wrapper.find('CardAbstract');
    const cardContainer = wrapper.find('CardContainer');
    const fieldsSplitColumns = wrapper.find('FieldsSplitColumns');

    //❓ Assert
    expect(areaField.exists()).toBe(true);
    expect(noCalcRF.exists()).toBe(true);
    expect(parentInfo.exists()).toBe(true);
    expect(isDiffHeightMarkField.exists()).toBe(true);
    expect(otherDocumentsRF.exists()).toBe(true);
    expect(tabs.prop('tabs')).toEqual([
      { id: 'characteristics-tab', name: 'Исходные данные' },
      { id: 'docs-tab', name: 'Документы' },
    ]);
    expect(cardAbstract.exists()).toBe(true);
    expect(cardContainer.exists()).toBe(true);
    expect(fieldsSplitColumns.exists()).toBe(true);
  });
});
