import { REAGENT_NAME } from 'core/form/finalForm/fields/prepared/selects/simple/ReagentFF';
import {
  REAGENT_KIND_NAME,
  ReagentKindFF,
} from 'core/form/finalForm/fields/prepared/selects/simple/ReagentKindFF';
import { shallow } from 'enzyme';
import { useField } from 'react-final-form';

import { BodyDialogRefill } from './Body.Dialog.Refill';

jest.mock('react-final-form');

describe('spec Body.Dialog.Refill', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useField.mockReturnValue({ input: {} });

    // 🧹 clear mock

    // 🔥 Act
    const wrapper = shallow(<BodyDialogRefill />);

    // ❓ Assert
    expect(wrapper.text()).toBe(
      '<KeyboardDateTimePickerFF /><ReagentKindFF /><ReagentFF /><NumberFieldFF /><TextFieldFF /><CommentFF />',
    );
    expect(useField).toHaveBeenNthCalledWith(1, REAGENT_KIND_NAME);
    expect(useField).toHaveBeenNthCalledWith(2, REAGENT_NAME);
  });

  it('props ReagentFF', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useField.mockReturnValue({ input: {} });

    // 🧹 clear mock

    // 🔥 Act
    const wrapper = shallow(<BodyDialogRefill />);

    // ❓ Assert
    expect(wrapper.find(ReagentKindFF).prop('required')).toBeTruthy();
  });
});
