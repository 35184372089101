import { render, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { OZN, SPA, YARD } from 'app/constants/oghTypes';
import React, { FC } from 'react';
import { Mock, vi } from 'vitest';

import { CleanCoverArea } from './CleanCoverArea';

vi.mock('app/api/hooks/useGetCurrentCard');
vi.mock('react', async (importOriginal) => {
  const mod = (await importOriginal()) as Record<string, unknown>; // Явное приведение к объекту;

  /**
   * Mock Field от redux form.
   *
   * @param p - Prop.
   * @param p.component - Component.
   * @param p.props - Properties.
   * @returns JSX.
   */
  const Field = ({
    component,
    ...props
  }: {
    component: FC<unknown>;
    props: object;
  }) => {
    const Component = component;
    // @ts-ignore
    return <Component {...props} />;
  };

  return {
    ...mod,

    Field,
  };
});

const calcAttributes = {
  artificial_ice_clean_area: 3,
  asphalt_clean_area: 1,
  auto_clean_area: 2,
  clinker_brick_area: 69,
  concrete_cell_clean_area: 60,
  cover_improve_usual_tiles_area: 75,
  equipment_index: 66,
  grass_clean_area: 33,
  grass_paver_clean_area: 54,
  lawn_area_without: 235,
  lawn_grid_area: 64,
  lawn_lawn_area: 364,
  lawn_other_area: 47,
  manual_clean_area: 12,
  natural_ice_clean_area: 665,
  oo_cover_acryl_area: 82,
  oo_cover_asphalt_crumb_area: 81,
  oo_cover_cement_concrete_area: 83,
  oo_cover_gravel_area: 85,
  oo_cover_improve_tactile_tiles_area: 92,
  oo_cover_macadam_area: 91,
  oo_cover_marble_slab_area: 89,
  oo_cover_noimprove_granite_area: 86,
  oo_cover_paving_stones_area: 84,
  oo_cover_sand_gravel_area: 90,
  oo_cover_steel_area: 88,
  oo_cover_stone_area: 95,
  oo_cover_trellised_flooring_area: 96,
  oo_cover_wood_area: 87,
  oo_cover_wood_flooring_area: 92,
  oo_granite_sowing_clean_area: 214,
  oo_granite_tiles_clean_area: 95,
  oo_terraway_clean_area: 93,
  oo_wood_chip_clean_area: 81,
  oo_wpc_flooring_clean_area: 94,
  parterre_lawn_area: 7,
  plastic_cell_clean_area: 61,
  plastic_clean_area: 432,
  rubber_clean_area: 34,
  sand_clean_area: 56,
  sand_clean_area_yo: 72,
  slope_lawn_area: 3312,
  soil_clean_area: 317,
  total_area: 34,
  total_clean_area: 78,
  total_cover_clean_area: 100,
  total_lawn_area: 3453,
  usial_lawn_area: 675,
  yard_cover_acryl_area: 57,
  yard_cover_asphalt_crumb_area: 58,
  yard_cover_cement_concrete_area: 59,
  yard_cover_gravel_area: 63,
  yard_cover_improve_tactile_tiles_area: 75,
  yard_cover_macadam_area: 76,
  yard_cover_marble_slab_area: 71,
  yard_cover_noimprove_granite_area: 64,
  yard_cover_paving_stones_area: 62,
  yard_cover_sand_gravel_area: 73,
  yard_cover_steel_area: 70,
  yard_cover_stone_area: 79,
  yard_cover_trellised_flooring_area: 74,
  yard_cover_wood_area: 66,
  yard_cover_wood_flooring_area: 78,
  yard_granite_sowing_clean_area: 213,
  yard_granite_tiles_clean_area: 65,
  yard_natural_ice_manual_clean_area: 68,
  yard_terraway_clean_area: 77,
  yard_wpc_flooring_clean_area: 80,
};
test('spec CleanCoverArea', async () => {
  expect.hasAssertions();
  // Arrange (всякие 849моки)
  (useGetCurrentCard as Mock).mockReturnValue({
    calc_attribute: calcAttributes,
    typeId: 34,
  });
  const user = userEvent.setup();

  // Act
  const { queryByLabelText, getByLabelText, rerender } = render(
    <CleanCoverArea />,
  );

  // Assert
  expect(
    queryByLabelText(/Общая уборочная площадь по покрытиям/i),
  ).toBeInTheDocument();

  // is hidden
  expect(queryByLabelText(/Асфальтобетонное/i)).not.toBeInTheDocument();

  // act - on click

  await user.click(
    screen.queryByText(
      /^Уборочная площадь по покрытиям/i,
    ) as HTMLTextAreaElement,
  );
  expect(getByLabelText(/Уборочная площадь по покрытиям/i)).toBeInTheDocument();

  const total_cover_clean_area = queryByLabelText(
    /Общая уборочная площадь по покрытиям/i,
  ) as HTMLTextAreaElement;
  expect(total_cover_clean_area).toBeDisabled();
  expect(total_cover_clean_area.value).toBe('100');

  const asphalt_clean_area = getByLabelText(
    /Асфальтобетонное/i,
  ) as HTMLTextAreaElement;
  expect(asphalt_clean_area).toBeDisabled();
  expect(asphalt_clean_area.value).toBe('1');

  const cover_improve_usual_tiles_area = getByLabelText(
    /Плиточное/i,
  ) as HTMLTextAreaElement;
  expect(cover_improve_usual_tiles_area).toBeDisabled();
  expect(cover_improve_usual_tiles_area.value).toBe('75');

  const soil_clean_area = getByLabelText(/Грунтовое/i) as HTMLTextAreaElement;
  expect(soil_clean_area).toBeDisabled();
  expect(soil_clean_area.value).toBe('317');

  const rubber_clean_area = getByLabelText(
    /Резиновая крошка/i,
  ) as HTMLTextAreaElement;
  expect(rubber_clean_area).toBeDisabled();
  expect(rubber_clean_area.value).toBe('34');

  const sand_clean_area = getByLabelText(
    /Мягкое из песка/i,
  ) as HTMLTextAreaElement;
  expect(sand_clean_area).toBeDisabled();
  expect(sand_clean_area.value).toBe('56');

  const grass_clean_area = getByLabelText(
    /Искусственная трава/i,
  ) as HTMLTextAreaElement;
  expect(grass_clean_area).toBeDisabled();
  expect(grass_clean_area.value).toBe('33');

  const plastic_clean_area = getByLabelText(
    /Пластиковое/i,
  ) as HTMLTextAreaElement;
  expect(plastic_clean_area).toBeDisabled();
  expect(plastic_clean_area.value).toBe('432');

  const grass_paver_clean_area = getByLabelText(
    /^На ячеистом основании /i,
  ) as HTMLTextAreaElement;
  expect(grass_paver_clean_area).toBeDisabled();
  expect(grass_paver_clean_area.value).toBe('54');

  // Проверка на отсутствие инпутов.
  expect(
    queryByLabelText(/Резиновое и иное искусственное/i),
  ).not.toBeInTheDocument();
  expect(queryByLabelText(/Акриловое \(хард\)/i)).not.toBeInTheDocument();
  expect(queryByLabelText(/Асфальтная крошка/i)).not.toBeInTheDocument();
  expect(queryByLabelText(/Цементобетонное/i)).not.toBeInTheDocument();
  expect(queryByLabelText(/Древесная щепа \(кора\)/i)).not.toBeInTheDocument();

  /////////////////////////////////////////////////

  // Act Рисуем SPA
  /////////////////
  (useGetCurrentCard as Mock).mockReturnValue({
    calc_attribute: calcAttributes,
    typeId: SPA,
  });
  rerender(<CleanCoverArea />);

  // Assert Для SPA
  expect(rubber_clean_area).toBeDisabled();
  expect(rubber_clean_area.value).toBe('34');
  expect(queryByLabelText(/Древесная щепа \(кора\)/i)).not.toBeInTheDocument();

  /////////////////////////////////////////////////
  // Рисуем YARD
  /////////////////
  // Текущие поля с атрибутами: «Мягкое из песка», «Мягкое из гранитной высевки», «На ячеистом основании/экопарковка» - удалить с карточек объектов ДТ.

  // Act

  (useGetCurrentCard as Mock).mockReturnValue({
    calc_attribute: calcAttributes,
    typeId: YARD,
  });

  rerender(<CleanCoverArea />);
  const total_cover_clean_area___YARD = queryByLabelText(
    /Общая уборочная площадь по покрытиям/i,
  ) as HTMLTextAreaElement;
  expect(total_cover_clean_area___YARD).toBeDisabled();
  expect(total_cover_clean_area___YARD.value).toBe('100');

  expect(
    queryByLabelText(/Мягкое из гранитной высевки/i),
  ).not.toBeInTheDocument();
  expect(queryByLabelText(/На ячеистом основании$/i)).not.toBeInTheDocument();

  const yard_cover_acryl_area = getByLabelText(
    /Акриловое \(хард\)/i,
  ) as HTMLTextAreaElement;
  expect(yard_cover_acryl_area).toBeDisabled();
  expect(yard_cover_acryl_area.value).toBe('57');

  const yard_cover_asphalt_crumb_area = getByLabelText(
    /Асфальтная крошка/i,
  ) as HTMLTextAreaElement;
  expect(yard_cover_asphalt_crumb_area).toBeDisabled();
  expect(yard_cover_asphalt_crumb_area.value).toBe('58');

  const yard_cover_cement_concrete_area = getByLabelText(
    /Цементобетонное/i,
  ) as HTMLTextAreaElement;
  expect(yard_cover_cement_concrete_area).toBeDisabled();
  expect(yard_cover_cement_concrete_area.value).toBe('59');

  const concrete_cell_clean_area = getByLabelText(
    /Бетонное на ячеистом основании \(экопарковка\)/,
  ) as HTMLTextAreaElement;
  expect(concrete_cell_clean_area).toBeDisabled();
  expect(concrete_cell_clean_area.value).toBe('60');

  const plastic_cell_clean_area = getByLabelText(
    /Пластиковое на ячеистом основании \(экопарковка\)/i,
  ) as HTMLTextAreaElement;
  expect(plastic_cell_clean_area).toBeDisabled();
  expect(plastic_cell_clean_area.value).toBe('61');

  const yard_cover_paving_stones_area = getByLabelText(
    /Брусчатка/i,
  ) as HTMLTextAreaElement;
  expect(yard_cover_paving_stones_area).toBeDisabled();
  expect(yard_cover_paving_stones_area.value).toBe('62');

  const yard_cover_gravel_area = getByLabelText(
    /Гравийное/i,
  ) as HTMLTextAreaElement;
  expect(yard_cover_gravel_area).toBeDisabled();
  expect(yard_cover_gravel_area.value).toBe('63');

  const yard_cover_noimprove_granite_area = getByLabelText(
    /Гранитное/i,
  ) as HTMLTextAreaElement;
  expect(yard_cover_noimprove_granite_area).toBeDisabled();
  expect(yard_cover_noimprove_granite_area.value).toBe('64');

  const yard_granite_sowing_clean_area = getByLabelText(
    /Гранитная высевка/i,
  ) as HTMLTextAreaElement;
  expect(yard_granite_sowing_clean_area).toBeDisabled();
  expect(yard_granite_sowing_clean_area.value).toBe('213');

  const yard_granite_tiles_clean_area = getByLabelText(
    /Гранитная плитка/i,
  ) as HTMLTextAreaElement;
  expect(yard_granite_tiles_clean_area).toBeDisabled();
  expect(yard_granite_tiles_clean_area.value).toBe('65');

  const yard_cover_wood_area = getByLabelText(
    /Деревянное/i,
  ) as HTMLTextAreaElement;
  expect(yard_cover_wood_area).toBeDisabled();
  expect(yard_cover_wood_area.value).toBe('66');

  const artificial_ice_clean_area = getByLabelText(
    /Искусственный лед/i,
  ) as HTMLTextAreaElement;
  expect(artificial_ice_clean_area).toBeDisabled();
  expect(artificial_ice_clean_area.value).toBe('3');

  const yard_natural_ice_manual_clean_area = getByLabelText(
    /Естественный лед/i,
  ) as HTMLTextAreaElement;
  expect(yard_natural_ice_manual_clean_area).toBeDisabled();
  expect(yard_natural_ice_manual_clean_area.value).toBe('665');

  const clinker_brick_area_yard = getByLabelText(
    /Клинкерный кирпич/i,
  ) as HTMLTextAreaElement;
  expect(clinker_brick_area_yard).toBeDisabled();
  expect(clinker_brick_area_yard.value).toBe('69');

  const yard_cover_steel_area = getByLabelText(
    /Металлическое/i,
  ) as HTMLTextAreaElement;
  expect(yard_cover_steel_area).toBeDisabled();
  expect(yard_cover_steel_area.value).toBe('70');

  const yard_cover_marble_slab_area = getByLabelText(
    /Мраморные плиты/i,
  ) as HTMLTextAreaElement;
  expect(yard_cover_marble_slab_area).toBeDisabled();
  expect(yard_cover_marble_slab_area.value).toBe('71');

  const sand_clean_area_yo = getByLabelText(/Песчаное/i) as HTMLTextAreaElement;
  expect(sand_clean_area_yo).toBeDisabled();
  expect(sand_clean_area_yo.value).toBe('56');

  const yard_cover_sand_gravel_area = getByLabelText(
    /Песчано-гравийная смесь/i,
  ) as HTMLTextAreaElement;
  expect(yard_cover_sand_gravel_area).toBeDisabled();
  expect(yard_cover_sand_gravel_area.value).toBe('73');

  const yard_cover_trellised_flooring_area = getByLabelText(
    /Решётчатый настил/i,
  ) as HTMLTextAreaElement;
  expect(yard_cover_trellised_flooring_area).toBeDisabled();
  expect(yard_cover_trellised_flooring_area.value).toBe('74');

  const yard_cover_improve_tactile_tiles_area = getByLabelText(
    /Тактильная плитка/i,
  ) as HTMLTextAreaElement;
  expect(yard_cover_improve_tactile_tiles_area).toBeDisabled();
  expect(yard_cover_improve_tactile_tiles_area.value).toBe('75');

  const yard_cover_macadam_area = getByLabelText(
    /Щебеночное/i,
  ) as HTMLTextAreaElement;
  expect(yard_cover_macadam_area).toBeDisabled();
  expect(yard_cover_macadam_area.value).toBe('76');

  const yard_terraway_clean_area = getByLabelText(
    /TerraWay/i,
  ) as HTMLTextAreaElement;
  expect(yard_terraway_clean_area).toBeDisabled();
  expect(yard_terraway_clean_area.value).toBe('77');

  const yard_cover_wood_flooring_area = getByLabelText(
    /Деревянный настил из террасной доски/i,
  ) as HTMLTextAreaElement;
  expect(yard_cover_wood_flooring_area).toBeDisabled();
  expect(yard_cover_wood_flooring_area.value).toBe('78');

  const yard_cover_stone_rea = getByLabelText(
    /Натуральный камень/i,
  ) as HTMLTextAreaElement;
  expect(yard_cover_stone_rea).toBeDisabled();
  expect(yard_cover_stone_rea.value).toBe('79');

  const yard_wpc_flooring_clean_area = getByLabelText(
    /Настил ДПК \(древесно-полимерный композит\)/i,
  ) as HTMLTextAreaElement;
  expect(yard_wpc_flooring_clean_area).toBeDisabled();
  expect(yard_wpc_flooring_clean_area.value).toBe('80');

  expect(queryByLabelText(/Древесная щепа \(кора\)/i)).not.toBeInTheDocument();

  /////////////////////////////////////////////////
  // Рисуем OZN
  //////////////
  /// Текущие поля с атрибутами: «Мягкое из песка», «Мягкое из гранитной высевки», «На ячеистом основании/экопарковка» - удалить с карточек объектов ОО.

  // Act
  (useGetCurrentCard as Mock).mockReturnValue({
    calc_attribute: calcAttributes,
    typeId: OZN,
  });

  rerender(<CleanCoverArea />);

  const total_cover_clean_area___OZN = queryByLabelText(
    /Общая уборочная площадь по покрытиям/i,
  ) as HTMLTextAreaElement;
  expect(total_cover_clean_area___OZN).toBeDisabled();
  expect(total_cover_clean_area___OZN.value).toBe('100');

  expect(queryByLabelText(/Мягкое из песка/i)).not.toBeInTheDocument();
  expect(
    queryByLabelText(/Мягкое из гранитной высевки/i),
  ).not.toBeInTheDocument();

  const oo_cover_acryl_area = getByLabelText(
    /Акриловое \(хард\)/i,
  ) as HTMLTextAreaElement;
  expect(oo_cover_acryl_area).toBeDisabled();
  expect(oo_cover_acryl_area.value).toBe('82');

  const oo_cover_asphalt_crumb_area = getByLabelText(
    /Асфальтная крошка/i,
  ) as HTMLTextAreaElement;
  expect(oo_cover_asphalt_crumb_area).toBeDisabled();
  expect(oo_cover_asphalt_crumb_area.value).toBe('81');

  const oo_cover_cement_concrete_area = getByLabelText(
    /Цементобетонное/i,
  ) as HTMLTextAreaElement;
  expect(oo_cover_cement_concrete_area).toBeDisabled();
  expect(oo_cover_cement_concrete_area.value).toBe('83');

  const concrete_manual_clean_area_ozn = getByLabelText(
    /Бетонное на ячеистом основании \(экопарковка\)/,
  ) as HTMLTextAreaElement;
  expect(concrete_manual_clean_area_ozn).toBeDisabled();
  expect(concrete_manual_clean_area_ozn.value).toBe('60');

  const paver_manual_clean_area_ozn = getByLabelText(
    /Пластиковое на ячеистом основании \(экопарковка\)/i,
  ) as HTMLTextAreaElement;
  expect(paver_manual_clean_area_ozn).toBeDisabled();
  expect(paver_manual_clean_area_ozn.value).toBe('61');

  const oo_cover_paving_stones_area = getByLabelText(
    /Брусчатка/i,
  ) as HTMLTextAreaElement;
  expect(oo_cover_paving_stones_area).toBeDisabled();
  expect(oo_cover_paving_stones_area.value).toBe('84');

  const oo_cover_gravel_area = getByLabelText(
    /Гравийное/i,
  ) as HTMLTextAreaElement;
  expect(oo_cover_gravel_area).toBeDisabled();
  expect(oo_cover_gravel_area.value).toBe('85');

  const oo_cover_noimprove_granite_area = getByLabelText(
    /Гранитное/i,
  ) as HTMLTextAreaElement;
  expect(oo_cover_noimprove_granite_area).toBeDisabled();
  expect(oo_cover_noimprove_granite_area.value).toBe('86');

  const oo_granite_sowing_clean_area = getByLabelText(
    /Гранитная высевка/i,
  ) as HTMLTextAreaElement;
  expect(oo_granite_sowing_clean_area).toBeDisabled();
  expect(oo_granite_sowing_clean_area.value).toBe('214');

  const oo_cover_wood_area = getByLabelText(
    /Деревянное/i,
  ) as HTMLTextAreaElement;
  expect(oo_cover_wood_area).toBeDisabled();
  expect(oo_cover_wood_area.value).toBe('87');

  expect(artificial_ice_clean_area).toBeDisabled();
  expect(artificial_ice_clean_area.value).toBe('3');

  const natural_ice_clean_area = getByLabelText(
    /Естественный лед/i,
  ) as HTMLTextAreaElement;
  expect(natural_ice_clean_area).toBeDisabled();
  expect(natural_ice_clean_area.value).toBe('665');

  const clinker_brick_area = getByLabelText(
    /Клинкерный кирпич/i,
  ) as HTMLTextAreaElement;
  expect(clinker_brick_area).toBeDisabled();
  expect(clinker_brick_area.value).toBe('69');

  const oo_cover_steel_area = getByLabelText(
    /Металлическое/i,
  ) as HTMLTextAreaElement;
  expect(oo_cover_steel_area).toBeDisabled();
  expect(oo_cover_steel_area.value).toBe('88');

  const oo_cover_marble_slab_area = getByLabelText(
    /Мраморные плиты/i,
  ) as HTMLTextAreaElement;
  expect(oo_cover_marble_slab_area).toBeDisabled();
  expect(oo_cover_marble_slab_area.value).toBe('89');

  const sand_clean_area_yo_ozn = getByLabelText(
    /Песчаное/i,
  ) as HTMLTextAreaElement;
  expect(sand_clean_area_yo_ozn).toBeDisabled();
  expect(sand_clean_area_yo_ozn.value).toBe('56');

  const oo_cover_sand_gravel_area = getByLabelText(
    /Песчано-гравийная смесь/i,
  ) as HTMLTextAreaElement;
  expect(oo_cover_sand_gravel_area).toBeDisabled();
  expect(oo_cover_sand_gravel_area.value).toBe('90');

  const oo_cover_trellised_flooring_area = getByLabelText(
    /Решётчатый настил/i,
  ) as HTMLTextAreaElement;
  expect(oo_cover_trellised_flooring_area).toBeDisabled();
  expect(oo_cover_trellised_flooring_area.value).toBe('96');

  const oo_cover_improve_tactile_tiles_area = getByLabelText(
    /Тактильная плитка/i,
  ) as HTMLTextAreaElement;
  expect(oo_cover_improve_tactile_tiles_area).toBeDisabled();
  expect(oo_cover_improve_tactile_tiles_area.value).toBe('92');

  const oo_cover_macadam_area = getByLabelText(
    /Щебеночное/i,
  ) as HTMLTextAreaElement;
  expect(oo_cover_macadam_area).toBeDisabled();
  expect(oo_cover_macadam_area.value).toBe('91');

  const oo_terraway_clean_area = getByLabelText(
    /TerraWay/i,
  ) as HTMLTextAreaElement;
  expect(oo_terraway_clean_area).toBeDisabled();
  expect(oo_terraway_clean_area.value).toBe('93');

  const oo_wood_chip_clean_area_ozn = getByLabelText(
    /Древесная щепа \(кора\)/i,
  ) as HTMLTextAreaElement;
  expect(oo_wood_chip_clean_area_ozn).toBeDisabled();
  expect(oo_wood_chip_clean_area_ozn.value).toBe('81');

  const oo_cover_wood_flooring_area = getByLabelText(
    /Деревянный настил из террасной доски/i,
  ) as HTMLTextAreaElement;
  expect(oo_cover_wood_flooring_area).toBeDisabled();
  expect(oo_cover_wood_flooring_area.value).toBe('92');

  const oo_cover_stone_area = getByLabelText(
    /Натуральный камень/i,
  ) as HTMLTextAreaElement;
  expect(oo_cover_stone_area).toBeDisabled();
  expect(oo_cover_stone_area.value).toBe('95');

  const oo_wpc_flooring_clean_area = getByLabelText(
    /Настил ДПК \(древесно-полимерный композит\)/i,
  ) as HTMLTextAreaElement;
  expect(oo_wpc_flooring_clean_area).toBeDisabled();
  expect(oo_wpc_flooring_clean_area.value).toBe('94');

  const oo_wood_chip_clean_area = getByLabelText(
    /Древесная щепа \(кора\)/i,
  ) as HTMLTextAreaElement;
  expect(oo_wood_chip_clean_area).toBeDisabled();
  expect(oo_wood_chip_clean_area.value).toBe('81');

  const oo_granite_tiles_clean_area = getByLabelText(
    /Гранитная плитка/i,
  ) as HTMLTextAreaElement;
  expect(oo_granite_tiles_clean_area).toBeDisabled();
  expect(oo_granite_tiles_clean_area.value).toBe('95');

  expect(queryByLabelText(/^На ячеистом основании/i)).not.toBeInTheDocument();
});
