import { validateRequiredFieldsAsync } from 'app/components/card/common/validation';
import { IsDiffHeightMarkRF } from 'core/form/reduxForm/fields/prepared/checkboxes/IsDiffHeightMark.RF';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import ManholesCard, { onSubmit } from './manholes';

vi.mock('app/components/card/common/validation');
vi.mock('./submit');
vi.mock('redux-form', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    reduxForm: vi.fn(() => (Component) => {
      return Component;
    }),
  };
});
vi.mock('react-redux', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    connect: vi.fn(() => (ba) => {
      return ba;
    }),
  };
});

describe('Manholes', function () {
  it('test', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const props = {
      card: { bord_begin: 1 },
      editMode: true,
      formValues: {
        bord_begin: 1,
      },
      isCurrentObjectIsExternalSystem: false,
      mode: {},
    };

    // Act
    const wrapper = shallow(<ManholesCard {...props} />);

    // Assert
    expect(wrapper.find(IsDiffHeightMarkRF).props()).toStrictEqual({
      disabled: false,
    });
  });
});

describe('🐛 onSubmit', () => {
  it('🧪 validateRequiredFieldsAsync', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    const props = { card: { type_id: 1 } };
    const onSubmitProps = onSubmit.bind({ props });

    // 🔥 Act
    onSubmitProps();

    // ❓ Assert
    expect(validateRequiredFieldsAsync).toHaveBeenCalledWith(
      { file_list: [] },
      undefined,
    );
  });
});
