import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useNavigateRouter } from 'core/utils/router';
import { shallow } from 'enzyme';
import { useLocation, useParams } from 'react-router-dom';
import { vi } from 'vitest';

import LeftPanelDefault from './LeftPanel';

vi.mock('react-router-dom');
vi.mock('core/utils/router');
vi.mock('app/api/hooks/useIsCurrentObjectIsExternalSystem');
vi.mock('app/api/hooks/useIsEditCurrentObjectIsExternalSystem');
vi.mock('app/api/hooks/useGetParentCard');
vi.mock('./CardLeftPanel', () => ({

  /**
   * Компонент.
   *
   * @returns {React.ReactElement}
   */
  Card: () => <div>Card</div>,
}));

/**
 * Дефолтные моки.
 *
 */
const defaultMock = () => {
  useParams.mockReturnValue({});
  useLocation.mockReturnValue({});
  useNavigateRouter.mockReturnValue({});
  useIsCurrentObjectIsExternalSystem.mockReturnValue(true);
  useIsEditCurrentObjectIsExternalSystem.mockReturnValue(true);
  useGetParentCard.mockReturnValue(123);
};

describe('🐛 LeftPanelSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    defaultMock();

    // 🔥 Act
    const wrapper = shallow(<LeftPanelDefault />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<Card />');
  });
});
