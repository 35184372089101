import { shallow } from 'enzyme';

import {
  DOCUMENT_FILE_NAME,
  DocumentApproveElectronicSignatureField,
} from './Document.ApproveElectronicSignature.Field';

jest.mock('react-final-form');

describe('DocumentApproveElectronicSignatureFieldJestSpec', function () {
  it('test render text', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const wrapper = shallow(<DocumentApproveElectronicSignatureField />);

    // Assert
    expect(wrapper.text()).toBe('<DndFileUploadFF />');
  });
  it('_test render text', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const wrapper = shallow(<DocumentApproveElectronicSignatureField />);

    // Assert
    expect(wrapper.props()).toStrictEqual({
      accept: ['png', 'jpg', 'jpeg', 'pdf'],
      allowedMessage: 'Допустимые форматы - ',
      disabled: true,
      name: DOCUMENT_FILE_NAME,
      text: 'Требуется документ-основание',
    });
  });
});
