import { formatResponseToGeoJSON } from 'app/actions/geometryActions/fetchCheckGeometryIntersections.DTW';
import { post } from 'app/api/crud/post';
import { useMapContext } from 'app/components/map/useMapContext';
import { formatGeometryObjectToUpdate } from 'core/uiKit/components/DTW/contexts/utils';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery/useMutationAdaptor';
import { log } from 'core/utils/log';

/**
 * Форматирует объект группы геометрий в массив для ключей parent и children.
 *
 * @param {object} geometry - Объект геометрии, который приходит с бэка.
 * @param {string?} id - Id объекта.
 * @param {string} layerType - Id объекта.
 * @returns {Array<object>} Массив объектов геометрии для ключей parent и children.
 */
const formatGeometryObjectWithIdToUpdate = (geometry, id, layerType) =>
  formatGeometryObjectToUpdate(
    {
      ...geometry,
      id: geometry.id || geometry.object_id || id,
    },
    layerType,
  );

/**
 * Форматирует геометрию в объект или массив для ключей parent и children в объекте для хранение геометрий.
 *
 * @param {object} geometry - Объект геометрии.
 * @param {boolean} children - Содержит ли геометрий дочерние объекты. Если да, то формируем объект, если нет, то массив.
 * @param {string?} id - Id объекта.
 * @param {string} layerType - Id объекта.
 * @returns {Array<object>|{children: Array<object>, parent: Array<object>}}
 */
export const formatGeometryToDraw = (geometry, children, id, layerType) =>
  children
    ? formatGeometryObjectWithIdToUpdate(geometry, id, layerType)
    : {
        children: (geometry?.child_object || [])
          .map((child) =>
            formatGeometriesToDraw(child, true, undefined, 'children'),
          )
          .flat(1),
        parent: formatGeometryObjectWithIdToUpdate(
          {
            ...geometry,
            child_object: [],
          },
          id,
          'parent',
        ),
      };

/**
 * Производит финальное форматирование объектов для хранения в контексте карты.
 *
 * @param {Array<{ lines: Array<object>, points: Array<object>, polygons: Array<object> }>|{ lines: Array<object>, points: Array<object>, polygons: Array<object> }} geometries - AS.
 * @param {boolean} children - Нужно ли создавать объект с дочерними элементами.
 * @param {string?} id - Id объекта.
 * @param {string} layerType - Id объекта.
 * @returns {{ parent: Array<object>, children: Array<object> }}
 */
export const formatGeometriesToDraw = (
  geometries,
  children = false,
  id,
  layerType,
) => formatGeometryToDraw(geometries, children, id, layerType);

/**
 * Запрос на получение геометрии групп.
 *
 * @param {object} params - Параметры запроса.
 * @returns {Promise<*|Response>}
 */
const postGroupGeometry = (params) => post('/ogh/geometry/group', params);

/**
 * Хук использования запроса групп.
 *
 * @returns {{getGroupGeometry: Function, isLoadingGroupGeometry: boolean}}
 */
export const useGroup = () => {
  const mapglContext = useMapContext();

  const {
    mapService,
    updateLoadedGeometries,
    drawGeometries,
    zoomToGeometries,
  } = mapglContext;

  const [getGroupGeometry, { isLoading }] = useMutationAdaptor(
    postGroupGeometry,
    {
      //

      /**
       * Ошибочный кейс.
       *
       * @param {Error} error - Ошибка.
       */
      onError: (error) => {
        log.info('postGroupGeometry не прошел', error);
      },

      /**
       * Успешный кейс.
       *
       * @param {Array} data - Ответ.
       * @param {{id: number}} dataRequest - Данные запроса.
       */
      onSuccess: (data, { id }) => {
        mapService?.setIsGroupSelected(true);

        const formattedData = formatGeometriesToDraw(
          formatResponseToGeoJSON(data)[0],
          false,
          id,
        );
        const newLoadedGeometries = updateLoadedGeometries(formattedData, true);
        const geometryInstances = drawGeometries(
          newLoadedGeometries,
          'replaceAll',
        );

        zoomToGeometries(geometryInstances.parent);
      },
    },
  );

  return { getGroupGeometry, isLoadingGroupGeometry: isLoading };
};
