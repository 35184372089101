import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { vi } from 'vitest';

import { useSetupBikeParkTypeIdField } from './useSetup.BikeParkTypeId.Field';

vi.mock('app/api/hooks/useGetParentCard');
vi.mock('app/pages/cardsOgh/hooks/useMode');
vi.mock('app/api/hooks/useIsEditCurrentObjectIsExternalSystem');

/**
 * Дефолтные моки.
 *
 * @returns {void}
 */
const defaultMock = () => {
  useGetParentCard.mockReturnValue({});
  useMode.mockReturnValue({});
  useIsEditCurrentObjectIsExternalSystem.mockReturnValue(true);
};

describe('🐛 useSetupBikeParkTypeIdField', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    defaultMock();

    // 🔥 Act

    const result = useSetupBikeParkTypeIdField();

    // ❓ Assert
    expect(result).toStrictEqual({
      disabled: true,
      filter: expect.any(Function),
    });
  });
  it('🧪 disabled', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    defaultMock();
    useMode.mockReturnValue({ editMode: true });
    useIsEditCurrentObjectIsExternalSystem.mockReturnValue(true);

    // 🔥 Act

    const result = useSetupBikeParkTypeIdField();

    // ❓ Assert
    expect(result).toStrictEqual({
      disabled: false,
      filter: expect.any(Function),
    });
  });
  it('🧪 filter', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    defaultMock();
    useGetParentCard.mockReturnValue({ typeId: 40 });

    // 🔥 Act
    const { filter } = useSetupBikeParkTypeIdField();
    const result = filter([
      {
        id: 2,
        name: 'Долгосрочного хранения',
        parent_ogh_object_type_list: [38],
      },
      {
        id: 2,
        name: 'Долгосрочного хранения',
        parent_ogh_object_type_list: [40],
      },
      {
        id: 1,
        name: 'Кратковременного хранения',
        parent_ogh_object_type_list: [38],
      },
    ]);

    // ❓ Assert
    expect(result).toStrictEqual([
      {
        id: 2,
        name: 'Долгосрочного хранения',
        parent_ogh_object_type_list: [40],
      },
    ]);
  });
});
