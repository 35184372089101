import { RANDOM_NUMBER } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import TreesAndShrubsCard, { onSubmit } from './trees_shrubs';

vi.mock('app/components/card/common/validation');
vi.mock('./submit');
vi.mock('redux-form', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    reduxForm: vi.fn(() => (Component) => {
      return Component;
    }),
  };
});

vi.mock('react-redux', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    connect: vi.fn(() => (ba) => {
      return ba;
    }),
  };
});

describe('TreesAndShrubsCard', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = {
      card: { bord_begin: 1, flat_element_type: [], type_id: RANDOM_NUMBER },
      card_type: 'TreesAndShrubsCard',
      editMode: true,
      formValues: {
        bord_begin: 1,
      },
      isEditCurrentObjectIsExternalSystem: false,

      mode: {},
    };

    // 🔥 Act

    const wrapper = shallow(<TreesAndShrubsCard {...props} />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<CardContainer />');
  });
  it('LifeFormTypeIdRfWrapper', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const changeFieldValueSpy = vi.fn();
    const props = {
      card: { bord_begin: 1, flat_element_type: [], type_id: RANDOM_NUMBER },
      card_type: 'TreesAndShrubsCard',
      changeFieldValue: changeFieldValueSpy,
      editMode: true,
      formValues: {
        bord_begin: 1,
      },
      isEditCurrentObjectIsExternalSystem: true,

      mode: {},
    };

    // 🔥 Act

    const wrapper = shallow(<TreesAndShrubsCard {...props} />);
    const { withChange } = wrapper.find('LifeFormTypeIdRfWrapper').props();
    withChange();

    // ❓ Assert
    expect(changeFieldValueSpy).toHaveBeenCalledWith('plant_type_id', null);
    expect(changeFieldValueSpy).toHaveBeenCalledWith('age', null);
    expect(changeFieldValueSpy).toHaveBeenCalledWith('diameter', null);
    expect(changeFieldValueSpy).toHaveBeenCalledWith('area', '');
    expect(changeFieldValueSpy).toHaveBeenCalledWith('quantity', '');
  });
});

describe('🐛 onSubmit', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = { card: { type_id: 31 }, mandatoryFields: [] };

    // 🔥 Act

    onSubmit({}, vi.fn(), props);

    // ❓ Assert
    expect(1).toBe(1);
  });
});
