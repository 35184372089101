import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useMapContext } from 'app/components/map/useMapContext';
import { useEffect, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

import { useDrawGeometry } from '../hooks/useDrawGeometry';
import { IGroupTree } from '../Tree';

/**
 * Сетап для Типы объектов огх от дерева элементов.
 *
 * @param props - Props.
 * @returns - Функции и флаги.
 */
export const useSetupGroupItemTree = (props: IGroupTree) => {
  const { elements, id, setLoadingRender, openAll, subTypes, typeId } = props;

  const [selected, setSelected] = useQueryParam('selected', StringParam);

  const { rootId: rootIdCurrent } = useGetCurrentCard();
  const isCurrentInSubgroup = subTypes?.some(({ elements }) =>
    elements?.some(({ rootId }) => String(rootId) === String(rootIdCurrent)),
  );
  const { mapService } = useMapContext();
  const isCurrentInElements = elements?.some(
    ({ rootId }) => String(rootId) === String(rootIdCurrent),
  );

  const [openSubTypes, setOpenSubTypes] = useState<boolean | undefined>(false);
  const [open, setOpen] = useState<boolean | undefined>(
    openAll || isCurrentInSubgroup || isCurrentInElements,
  );

  const active = String(selected) === String(id);
  const { drawGeometry } = useDrawGeometry();

  /**
   * Подгрузить геометрию группы.
   *
   * @returns {void}
   */
  const onSelectGroup = (): void => {
    // HACK: Сначала длаем louder true.
    setLoadingRender(true);

    setSelected('' + id);
    mapService?.setIsGroupSelected(true);
    drawGeometry({ typeId });
  };

  useEffect(() => {
    setOpenSubTypes(openAll);
    setOpen(openAll);
  }, [openAll]);

  useEffect(() => {
    setOpen(openSubTypes);
  }, [openSubTypes]);

  // useEffect(() => {
  //   setOpenSubTypes(openAll);
  // }, [open]);

  // useEffect(() => {
  //   setOpen(isCurrentInSubgroup || isCurrentInElements);
  // }, [isCurrentInSubgroup, isCurrentInElements]);

  return {
    active,
    onSelectGroup,
    open,
    openSubTypes,
    setOpen,
    setOpenSubTypes,
    setSelected,
  };
};
