import { getYesterdayDateSting } from 'app/utils/date/getYesterdayDateSting';
import { RANDOM_BOOL } from 'core/forTesting/constants';
import { shallow } from 'enzyme';

import { DateCloseField } from './DateCloseField';
import { useGetDisabledDateCloseField } from './useGetDisabled.DateCloseField';

jest.mock('./useGetDisabled.DateCloseField');

describe('DateCloseFieldJestSpec', function () {
  it('test render comp', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetDisabledDateCloseField.mockReturnValue(RANDOM_BOOL);

    // Act
    const wrapper = shallow(<DateCloseField />);

    // Assert
    expect(wrapper.text()).toBe('<KeyboardDatePickerFF />');
  });

  it('testa render comp', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetDisabledDateCloseField.mockReturnValue(RANDOM_BOOL);

    // Act
    const wrapper = shallow(<DateCloseField />);

    // Assert
    expect(wrapper.props()).toStrictEqual({
      disabled: RANDOM_BOOL,
      label: 'Дата закрытия',
      minDate: getYesterdayDateSting(),
      name: 'date',
    });
  });
});
