import { shallow } from 'enzyme';
import React from 'react';
import { vi } from 'vitest';

import { DtsTypeIdRFField } from './DtsTypeId.RF.Field';
import { useSetupDtsTypeIdRFField } from './useSetup.DtsTypeId.RF.Field';

vi.mock('./useSetup.DtsTypeId.RF.Field');
vi.mock('core/form/reduxForm/fields/default/selects/SelectRF');

describe('🐛 spec DtsTypeId.RF.Field', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    useSetupDtsTypeIdRFField.mockReturnValue({});

    // 🧹 clear mock

    //🔥 Act
    const wrapper = shallow(<DtsTypeIdRFField />);

    //❓ Assert
    expect(wrapper.text()).toBe('<SelectRF />');
  });

  it('🧪 props', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    useSetupDtsTypeIdRFField.mockReturnValue({
      disabled: 'disabled',
      filter: 'filter',
      options: 'options',
    });

    // 🧹 clear mock

    //🔥 Act
    const wrapper = shallow(<DtsTypeIdRFField />);

    //❓ Assert
    expect(wrapper.props()).toStrictEqual({
      disabled: 'disabled',
      filter: 'filter',
      label: 'Назначение',
      name: 'dts_type_id',
      options: 'options',
      required: true,
    });
  });
});
