import { shallow } from 'enzyme';

import { BodyDialogRepairsInfoListPlan } from './BodyDialog.RepairsInfoListPlan';

describe('BodyDialogRepairsInfoListPlanSpec', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act

    const wrapper = shallow(<BodyDialogRepairsInfoListPlan />);

    // ❓ Assert
    expect(wrapper.text()).toBe(
      '<TextFieldFF /><DatePickerFF /><DatePickerFF /><TextFieldFF /><DndFileUploadFF />',
    );
  });

  it('should pass correct props to TextFieldFF', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act
    const wrapper = shallow(<BodyDialogRepairsInfoListPlan />);

    const nameFieldProps = wrapper.find('TextFieldFF').at(0).props();
    const propertyFieldProps = wrapper.find('TextFieldFF').at(1).props();

    // ❓ Assert
    expect(nameFieldProps).toMatchObject({
      label: 'Наименование',
      name: 'name',
      required: true,
    });
    expect(propertyFieldProps).toMatchObject({
      label: 'Техническое задание (характеристика объемы)',
      name: 'property',
    });
  });

  it('props DatePickerFF', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act
    const wrapper = shallow(<BodyDialogRepairsInfoListPlan />);
    const dateStartProps = wrapper.find('DatePickerFF').at(0).props();
    const dateEndProps = wrapper.find('DatePickerFF').at(1).props();

    expect(dateStartProps).toStrictEqual({
      inputProps: { placeholder: 'ДД.ММ.ГГГГ' },
      label: 'Дата начала работ',
      name: 'date_start',
      required: true,
    });

    // ❓ Assert
    expect(dateEndProps).toStrictEqual({
      inputProps: { placeholder: 'ДД.ММ.ГГГГ' },
      label: 'Дата окончания работ',
      name: 'date_end',
      required: true,
    });
  });

  it('should pass correct props to DndFileUploadFF', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act
    const wrapper = shallow(<BodyDialogRepairsInfoListPlan />);

    // ❓ Assert
    expect(wrapper.find('DndFileUploadFF').props()).toStrictEqual({
      multiple: false,
      required: true,
      text: 'Переместите файл технического задания / проекта благоустройства или нажмите для выбора',
    });
  });
});
