import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import { RANDOM_WORD } from 'core/forTesting/constants';
import { mount, shallow } from 'enzyme';
import React from 'react';

import { GroupIdRFPlanarStructureCard } from './GroupId.RF.PlanarStructureCard';
import { useGetGroupId } from './useGetGroupId';

jest.mock('app/api/hooks/useGetCurrentCard');
jest.mock('./useGetGroupId');
jest.mock('core/form/reduxForm/fields/default/selects/SelectRF');
jest.mock('react', () => ({
  ...jest.requireActual('react'),
}));

describe('GroupIdRFPlanarStructureCard', () => {
  const mockProps = {
    editMode: true,
  };
  const listGroupId = [{ group_id: 1 }, { group_id: 2 }, { group_id: 3 }];
  it('recordId есть в списке listGroupId', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    const recordId = 2;
    useGetCurrentCard.mockReturnValue({ listGroupId, recordId });

    // Act
    const wrapper = mount(<GroupIdRFPlanarStructureCard {...{ mockProps }} />);

    const { options } = wrapper.find(SelectRF).props();

    // Assert
    expect(options).toStrictEqual([
      { id: 1, name: 1 },
      { id: 3, name: 3 },
      { id: 2, name: 2 },
      { id: null, name: '<удалить group_id>' },
    ]);
  });
  it('arecordId есть в списке listGroupId', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    const recordId = 4;
    useGetCurrentCard.mockReturnValue({ listGroupId, recordId });

    // Act
    const wrapper = mount(<GroupIdRFPlanarStructureCard {...{ mockProps }} />);

    const { options } = wrapper.find(SelectRF).props();

    // Assert
    expect(options).toStrictEqual([
      { id: 1, name: 1 },
      { id: 2, name: 2 },
      { id: 3, name: 3 },
      { id: 4, name: '<cоздать новый group_id>' },
    ]);
  });
  it('listGroupId null', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    const recordId = 4;
    useGetCurrentCard.mockReturnValue({ listGroupId: null, recordId });

    // Act
    const wrapper = mount(<GroupIdRFPlanarStructureCard {...{ mockProps }} />);

    const { options } = wrapper.find(SelectRF).props();

    // Assert
    expect(options).toStrictEqual([
      { id: 4, name: '<cоздать новый group_id>' },
    ]);
  });
  it('выбрано значение удалить значение', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    const recordId = 2;
    useGetGroupId.mockReturnValue(null);
    useGetCurrentCard.mockReturnValue({ listGroupId, recordId });

    // Act
    const wrapper = mount(<GroupIdRFPlanarStructureCard {...{ mockProps }} />);

    const { options } = wrapper.find(SelectRF).props();

    // Assert
    expect(options).toStrictEqual([
      { id: 1, name: 1 },
      { id: 3, name: 3 },
      { id: 2, name: '<cоздать новый group_id>' },
    ]);
  });
  it('olistGroupId null', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    const recordId = 4;
    useGetGroupId.mockReturnValue(4);
    useGetCurrentCard.mockReturnValue({ listGroupId: null, recordId });

    // Act
    const wrapper = mount(<GroupIdRFPlanarStructureCard {...{ mockProps }} />);

    const { options } = wrapper.find(SelectRF).props();

    // Assert
    expect(options).toStrictEqual([
      { id: 4, name: 4 },
      { id: null, name: '<удалить group_id>' },
    ]);
  });
  it('render props', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    const recordId = 4;
    useGetGroupId.mockReturnValue(4);
    useGetCurrentCard.mockReturnValue({ listGroupId: null, recordId });

    // Act
    const wrapper = shallow(
      <GroupIdRFPlanarStructureCard {...{ mockProps }} />,
    );

    // Assert
    expect(wrapper.props()).toStrictEqual({
      disabled: true,
      filter: wrapper.props().filter,
      label: 'Является частью составного объекта',
      name: 'group_id',
      options: [{ id: 4, name: '<cоздать новый group_id>' }],
      withAll: false,
    });
  });
  it('test filter', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    const recordId = 4;
    useGetGroupId.mockReturnValue(4);
    useGetCurrentCard.mockReturnValue({ listGroupId: null, recordId });

    // Act
    const wrapper = shallow(
      <GroupIdRFPlanarStructureCard {...{ mockProps }} />,
    );

    const { filter } = wrapper.props();

    // Assert
    expect(filter(RANDOM_WORD)).toBe(RANDOM_WORD);
  });
});
