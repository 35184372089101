import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { RANDOM_ID } from 'core/forTesting/constants';
import { vi } from 'vitest';

import { useSetupArrangeElementTypeIdField } from './useSetup.ArrangeElementTypeId.Field';

vi.mock('app/pages/cardsOgh/hooks/useMode');
vi.mock('app/api/hooks/useIsEditCurrentObjectIsExternalSystem');
vi.mock('app/api/hooks/autocomplete/useGetDictionary');
vi.mock('app/api/hooks/useGetParentCard');

/**
 * Mock Default.
 *
 * @returns {void}
 */
const mockDefault = () => {
  useMode.mockReturnValue({ editMode: true });
  useIsEditCurrentObjectIsExternalSystem.mockReturnValue(false);
  useGetParentCard.mockReturnValue({ typeId: RANDOM_ID });
};

describe('🐛 spec useSetup.ArrangeElementTypeId.Field', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    mockDefault();

    // 🧹 clear mock

    //🔥 Act
    const res = useSetupArrangeElementTypeIdField();

    //❓ Assert
    expect(res).toStrictEqual({
      disabled: true,
      filter: expect.any(Function),
      options: expect.any(Array),
    });
  });

  it('🧪 filter', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    mockDefault();
    useGetParentCard.mockReturnValue({ typeId: 38 });

    // 🧹 clear mock

    //🔥 Act
    const { filter } = useSetupArrangeElementTypeIdField();
    const res = filter(expectOptions);

    //❓ Assert
    expect(res).toStrictEqual(expectFilter);
  });

  it('🧪 options', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    mockDefault();
    useGetParentCard.mockReturnValue({ typeId: 38 });

    // 🧹 clear mock

    //🔥 Act
    const { options } = useSetupArrangeElementTypeIdField();

    //❓ Assert
    expect(options).toStrictEqual(expectOptions);
  });
});

const expectOptions = [
  {
    code: 'solar_power_plant',
    id: 101001,
    migr_code: [],
    name: 'Автономная электростанция на солнечной энергии',
    ogh_object_type_id: {
      ogh_object_type: 35,
    },
    parent_ogh_object_type_list: [49],
    unit_id: {
      unit: 5,
    },
  },
  {
    code: 'wireless_internet_module',
    id: 101000,
    migr_code: [],
    name: 'Автономный модуль для организации беспроводного доступа в интернет',
    ogh_object_type_id: {
      ogh_object_type: 35,
    },
    parent_ogh_object_type_list: [49],
    unit_id: {
      unit: 5,
    },
  },
  {
    code: 'overall_column',
    id: 100848,
    migr_code: [],
    name: 'Габаритный столбик',
    ogh_object_type_id: {
      ogh_object_type: 35,
    },
    parent_ogh_object_type_list: [38, 39, 40, 49],
    unit_id: {
      unit: 5,
    },
  },
  {
    code: 'gabion',
    id: 49,
    migr_code: [],
    name: 'Габион',
    ogh_object_type_id: {
      ogh_object_type: 34,
    },
    parent_ogh_object_type_list: [38, 39, 40, 49],
    unit_id: {
      unit: 2,
    },
  },
];

const expectFilter = [
  {
    code: 'overall_column',
    id: 100848,
    migr_code: [],
    name: 'Габаритный столбик',
    ogh_object_type_id: {
      ogh_object_type: 35,
    },
    parent_ogh_object_type_list: [38, 39, 40, 49],
    unit_id: {
      unit: 5,
    },
  },
  {
    code: 'gabion',
    id: 49,
    migr_code: [],
    name: 'Габион',
    ogh_object_type_id: {
      ogh_object_type: 34,
    },
    parent_ogh_object_type_list: [38, 39, 40, 49],
    unit_id: {
      unit: 2,
    },
  },
];
