import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import { MainParamsTab } from './MainParamsTab';

vi.mock('app/api/hooks/useGetCurrentCard');
vi.mock('app/api/hooks/useIsEditCurrentObjectIsExternalSystem');
vi.mock('app/pages/cardsOgh/hooks/useMode');

/**
 * Дефолтные пропсы.
 *
 * @returns {void}
 */
const defaultProps = () => {
  useGetCurrentCard.mockReturnValue({ calcAttribute: {}, typeId: 1 });
  useIsEditCurrentObjectIsExternalSystem.mockReturnValue(true);
  useMode.mockReturnValue({ editMode: true });
};

describe('🐛 MainParamsTabSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    defaultProps();

    // 🔥 Act
    const wrapper = shallow(<MainParamsTab />);

    // ❓ Assert
    expect(wrapper.text()).toBe(
      '<FieldsSplitColumns /><GroupContainer /><CleanCoverArea /><LawnArea /><DataPassport /><GroupContainer /><GroupContainer />',
    );
  });
});
