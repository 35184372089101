import { validateRequiredFieldsAsync } from 'app/components/card/common/validation';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import FencingCard, { mapStateToProps } from './fencing';
import submitCard from './submit';

vi.mock('app/components/card/common/validation');
vi.mock('./submit');

vi.mock('redux-form', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    reduxForm: vi.fn(() => (Component) => {
      return Component;
    }),
  };
});

vi.mock('react-redux', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    connect: vi.fn(() => (value) => {
      return value;
    }),
  };
});

describe('FencingJestSpec', function () {
  it('test IsDiffHeightMarkRF render card', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const props = {
      card: { bord_begin: 1 },
      editMode: true,
      formValues: {
        bord_begin: 1,
      },
      isCurrentObjectIsExternalSystem: false,
      mode: {},
    };

    // Act
    const wrapper = shallow(<FencingCard {...props} />);

    // Assert
    expect(wrapper.find('IsDiffHeightMarkField').props()).toStrictEqual({});
  });
  it('test', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const { onSubmit } = mapStateToProps({}, { card: { attribute: {} } });
    onSubmit({});

    // Assert
    expect(submitCard).toHaveBeenCalledTimes(1);
    expect(validateRequiredFieldsAsync).toHaveBeenCalledTimes(1);
  });
});
