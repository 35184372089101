import { BarnewJerseyHints } from 'app/components/card/ogh/Odh/components/hints/BarnewJersey.Hints/BarnewJersey.Hints';
import { create } from 'react-test-renderer';

describe('BarnewJerseyHints', function () {
  it('test', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const root = create(<BarnewJerseyHints />);

    // Assert
    expect(root.toJSON()).toMatchSnapshot();
  });
});
