import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';
import { SelectPreparedProps } from 'types/inputs';

export const BIKE_PARK_TYPE_ID_NAME = 'bike_park_type_id';

export interface IBikeParkTypeData {
  id: number;
  code: string;
  name: string;
  parent_ogh_object_type_list: number[];
}

export interface IBikeParkTypeIdProps extends SelectPreparedProps {
  filter?: (data: IBikeParkTypeData[]) => IBikeParkTypeData[];
}

/**
 * ## Select __Тип велопарковки__.
 *
 * @param props - Пропсы.
 * @returns Select __Тип велопарковки__.
 */
export const BikeParkTypeId = (props: IBikeParkTypeIdProps) => {
  const { data = [] } = useGetDictionary<IBikeParkTypeData[]>('bikeParkType');

  return (
    <Select
      {...props}
      options={data || []}
      label={'Тип велопарковки'}
      name={BIKE_PARK_TYPE_ID_NAME}
    />
  );
};
