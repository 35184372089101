import { OznLazy } from 'app/pages/registry/ogh/ozn/Ozn';
import { pathOzn } from 'app/pages/registry/ogh/ozn/Ozn/path.Ozn';
import { AbutmentLazy } from 'app/pages/registry/ogh/yard/Abutment';
import { BuildingLazy } from 'app/pages/registry/ogh/yard/Building';
import { pathBuilding } from 'app/pages/registry/ogh/yard/Building/path.Building';
import { ContainerOdhLazy } from 'app/pages/registry/ogh/yard/Container';
import { DtsLazy } from 'app/pages/registry/ogh/yard/Dts';
import { pathDts } from 'app/pages/registry/ogh/yard/Dts/path.Dts';
import { EngineerBuildingLazy } from 'app/pages/registry/ogh/yard/EngineerBuilding';
import { pathEngineerBuilding } from 'app/pages/registry/ogh/yard/EngineerBuilding/path.EngineerBuilding';
import { FlowersGardenLazy } from 'app/pages/registry/ogh/yard/FlowersGarden';
import { pathFlowersGarden } from 'app/pages/registry/ogh/yard/FlowersGarden/path.FlowersGarden';
import { FunctionalityLazy } from 'app/pages/registry/ogh/yard/Functionality';
import { pathFunctionality } from 'app/pages/registry/ogh/yard/Functionality/path.Functionality';
import { LawnLazy } from 'app/pages/registry/ogh/yard/Lawn';
import { pathLawn } from 'app/pages/registry/ogh/yard/Lawn/path.Lawn';
import { LittleFormLazy } from 'app/pages/registry/ogh/yard/LittleForm';
import { pathLittleForm } from 'app/pages/registry/ogh/yard/LittleForm/path.LittleForm';
import { OtherTechPlaceLazy } from 'app/pages/registry/ogh/yard/OtherTechPlace';
import { pathOtherTechPlace } from 'app/pages/registry/ogh/yard/OtherTechPlace/path.OtherTechPlace';
import { PlanarStructureLazy } from 'app/pages/registry/ogh/yard/PlanarStructure';
import { pathPlanarStructure } from 'app/pages/registry/ogh/yard/PlanarStructure/path.PlanarStructure';
import { PpiLazy } from 'app/pages/registry/ogh/yard/Ppi';
import { pathPpi } from 'app/pages/registry/ogh/yard/Ppi/path.Ppi';
import { RedBookPlantLazy } from 'app/pages/registry/ogh/yard/RedBookPlant';
import { pathRedBookPlant } from 'app/pages/registry/ogh/yard/RedBookPlant/path.RedBookPlant';
import { ReliefLazy } from 'app/pages/registry/ogh/yard/Relief';
import { pathRelief } from 'app/pages/registry/ogh/yard/Relief/path.Relief';
import { StoragePlaceLazy } from 'app/pages/registry/ogh/yard/StoragePlace';
import { pathStoragePlace } from 'app/pages/registry/ogh/yard/StoragePlace/path.StoragePlace';
import { TreesShrubsLazy } from 'app/pages/registry/ogh/yard/TreesShrubs';
import { pathTreesShrubs } from 'app/pages/registry/ogh/yard/TreesShrubs/path.TreesShrubs';
import { WaterBuildingLazy } from 'app/pages/registry/ogh/yard/WaterBuilding';
import { pathWaterBuilding } from 'app/pages/registry/ogh/yard/WaterBuilding/path.WaterBuilding';
import { pathContainer } from 'app/pages/registry/ogh/сontainer/Container/path.Container';
import { useGetOghType } from 'core/utils/hooks/useGetOghType';
import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { pathAbutment } from '../yard/Abutment/path.Abutment';
import { VerticalLandscapingLazy } from '../yard/VerticalLandscaping';
import { pathVerticalLandscaping } from '../yard/VerticalLandscaping/path.VerticalLandscaping';
import { treeOzn } from './tree.Ozn';

/**
 * Router для Объектов озеленения.
 *
 * @returns {JSX.Element}
 */
const OznRoute = () => {
  useEffect(() => {
    BuildingLazy.preload();
    ContainerOdhLazy.preload();
    DtsLazy.preload();
    FlowersGardenLazy.preload();
    FunctionalityLazy.preload();
    LawnLazy.preload();
    LittleFormLazy.preload();
    OtherTechPlaceLazy.preload();
    OznLazy.preload();
    PlanarStructureLazy.preload();
    PpiLazy.preload();
    RedBookPlantLazy.preload();
    ReliefLazy.preload();
    StoragePlaceLazy.preload();
    TreesShrubsLazy.preload();
    WaterBuildingLazy.preload();
    VerticalLandscapingLazy.preload();
    EngineerBuildingLazy.preload();
    AbutmentLazy.preload();
  });
  const { id: parentTypeId } = useGetOghType(pathOzn.code);

  return (
    <Routes>
      <Route element={<OznLazy />} path={'/'} exact={true} />
      <Route
        element={
          <PlanarStructureLazy tree={treeOzn} parentTypeId={parentTypeId} />
        }
        path={pathPlanarStructure.path}
      />
      <Route
        element={
          <OtherTechPlaceLazy tree={treeOzn} parentTypeId={parentTypeId} />
        }
        path={pathOtherTechPlace.path}
      />
      <Route
        element={<BuildingLazy tree={treeOzn} parentTypeId={parentTypeId} />}
        path={pathBuilding.path}
      />
      <Route
        element={
          <ContainerOdhLazy tree={treeOzn} parentTypeId={parentTypeId} />
        }
        path={pathContainer.path}
      />
      <Route
        element={
          <StoragePlaceLazy tree={treeOzn} parentTypeId={parentTypeId} />
        }
        path={pathStoragePlace.path}
      />
      <Route
        element={<DtsLazy tree={treeOzn} parentTypeId={parentTypeId} />}
        path={pathDts.path}
      />
      <Route
        element={<PpiLazy tree={treeOzn} parentTypeId={parentTypeId} />}
        path={pathPpi.path}
      />
      <Route
        element={<ReliefLazy tree={treeOzn} parentTypeId={parentTypeId} />}
        path={pathRelief.path}
      />
      <Route
        element={
          <FunctionalityLazy tree={treeOzn} parentTypeId={parentTypeId} />
        }
        path={pathFunctionality.path}
      />
      <Route
        element={<LittleFormLazy tree={treeOzn} parentTypeId={parentTypeId} />}
        path={pathLittleForm.path}
      />
      <Route
        element={<LawnLazy tree={treeOzn} parentTypeId={parentTypeId} />}
        path={pathLawn.path}
      />
      <Route
        element={
          <FlowersGardenLazy tree={treeOzn} parentTypeId={parentTypeId} />
        }
        path={pathFlowersGarden.path}
      />
      <Route
        element={<TreesShrubsLazy tree={treeOzn} parentTypeId={parentTypeId} />}
        path={pathTreesShrubs.path}
      />
      <Route
        element={
          <WaterBuildingLazy tree={treeOzn} parentTypeId={parentTypeId} />
        }
        path={pathWaterBuilding.path}
      />
      <Route
        element={
          <RedBookPlantLazy tree={treeOzn} parentTypeId={parentTypeId} />
        }
        path={pathRedBookPlant.path}
      />
      <Route
        element={
          <VerticalLandscapingLazy tree={treeOzn} parentTypeId={parentTypeId} />
        }
        path={pathVerticalLandscaping.path}
      />
      <Route
        element={
          <EngineerBuildingLazy tree={treeOzn} parentTypeId={parentTypeId} />
        }
        path={pathEngineerBuilding.path}
      />
      <Route
        element={<AbutmentLazy tree={treeOzn} parentTypeId={parentTypeId} />}
        path={pathAbutment.path}
      />
    </Routes>
  );
};

export default OznRoute;
