import { RANDOM_NUMBER } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import RoadSignsCard, { onSubmit } from './road_signs';

vi.mock('app/components/card/common/validation');
vi.mock('./submit');
vi.mock('redux-form', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    reduxForm: vi.fn(() => (Component) => {
      return Component;
    }),
  };
});

vi.mock('react-redux', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    connect: vi.fn(() => (ba) => {
      return ba;
    }),
  };
});

describe('RoadSignsCard', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = {
      card: { bord_begin: 1, flat_element_type: [], type_id: RANDOM_NUMBER },
      card_type: 'RoadSignsCard',
      editMode: true,
      formValues: {
        bord_begin: 1,
      },
      isCurrentObjectIsExternalSystem: false,

      mode: {},
    };

    // 🔥 Act

    const wrapper = shallow(<RoadSignsCard {...props} />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<CardContainer />');
  });

  it('fields', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = {
      card: {
        equipment_type: [],
        mounting_mode: [],
        type_id: RANDOM_NUMBER,
      },
      editMode: true,
      isEditCurrentObjectIsExternalSystem: true,
    };

    // 🔥 Act
    const wrapper = shallow(<RoadSignsCard {...props} />);

    // ❓ Assert
    expect(wrapper.find('EquipmentTypeIdField')).toBeTruthy();
    expect(wrapper.find('MountingModeIdField')).toBeTruthy();
  });
});

describe('🐛 onSubmit', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = { card: { type_id: 31 } };
    const onSubmitProps = onSubmit.bind({ props });

    // 🔥 Act

    onSubmitProps();

    // ❓ Assert
    expect(1).toBe(1);
  });
});
