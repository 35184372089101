import { shallow } from 'enzyme';

import { KeyboardDateStartComponent } from './KeyboardDateStartComponent';

describe('KeyboardDateStartComponentJestSpec', function () {
  it('test component', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const wrapper = shallow(<KeyboardDateStartComponent />);

    // Assert
    expect(wrapper.text()).toBe('<KeyboardDatePickerRF />');
  });
  it('test props', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const wrapper = shallow(<KeyboardDateStartComponent />);

    // Assert
    wrapper.props();
    expect(wrapper.props()).toStrictEqual({
      disabled: true,
      label: 'Действует с',
      name: 'startDate',
    });
  });
});
