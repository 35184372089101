import { useFormValuesCardRF } from 'app/actions/odsObjectActions/useFormValuesCardRF';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { shallow } from 'enzyme';

import { CleanSpCategoryIdField } from './CleanSpCategoryIdField';

jest.mock('app/pages/cardsOgh/hooks/useEditMode');
jest.mock('app/actions/odsObjectActions/useFormValuesCardRF');

describe('CleanSpCategoryIdField', function () {
  it('test render is_orphan_object 0', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    useEditMode.mockReturnValue({ editMode: true });
    useFormValuesCardRF.mockReturnValue({ is_orphan_object: 0 });

    // Act
    const wrapper = shallow(<CleanSpCategoryIdField />);

    // Assert
    expect(wrapper.props()).toStrictEqual({
      disabled: false,
      helpTitle:
        'П.11.4 СП 42.13330.2016. Свод правил. Градостроительство. Планировка и застройка городских и сельских поселений. Актуализированная редакция СНиП 2.07.01-89.',
      required: true,
      withAll: false,
    });
    expect(wrapper.text()).toBe('<CleanSpCategoryIdRf />');
  });
  it('test render is_orphan_object 1', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    useEditMode.mockReturnValue({ editMode: true });
    useFormValuesCardRF.mockReturnValue({ is_orphan_object: 1 });

    // Act
    const wrapper = shallow(<CleanSpCategoryIdField />);

    // Assert
    expect(wrapper.text()).toBe('');
  });
});
