// 28 Некапитальные объекты для обеспечения производственной деятельности по содержанию и ремонту территорий (в том числе базы хранения ПГР на дворовых территорияхimport CardAbstract from 'app/components/card/common/CardAbstract';
import CardAbstract from 'app/components/card/common/CardAbstract';
import {
  elementFormGrid,
  renderAddressList2,
} from 'app/components/card/common/grid/index';
import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import { IsDiffHeightMarkField } from 'app/components/card/ogh/components/fields/checkBoxFields/IsDiffHeightMark.Field';
import { AreaField } from 'app/components/card/ogh/components/fields/numberFields/Area.Field';
import {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import { TECH_PLACE } from 'app/constants/oghTypes';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import getFormValues from 'app/selectors/form/getFormValues';
import getDict from 'app/selectors/getDict';
import { getInitialBuildingsTypeId } from 'app/utils/card/getInitialBuildingsTypeId';
import { transformValues } from 'app/utils/form/transformation';
import getHint from 'app/utils/getAddressListHints';
import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import { NoCalcRF } from 'core/form/reduxForm/fields/prepared/checkboxes/NoCalc.RF';
import { OtherDocumentsRF } from 'core/form/reduxForm/fields/prepared/tables/documentTables/OtherDocuments.RF';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/textField/TotalAreaGeo.RF';
import { CardContainer } from 'core/uiKit/components/card.components/CardContainer';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import printOghCardHeader from './header/oghHeader';
import submitCard from './submit';
import VersionsTemplate from './versions/VersionsTemplate';

const Versions = VersionsTemplate(['tech_place', 'storage_place']);

const tabs = [
  {
    id: 'characteristics-tab',
    name: 'Исходные данные',
  },
  {
    id: 'docs-tab',
    name: 'Документы',
  },
];

/**
 * Классовый компонет TechPlaceCard.
 */
class TechPlaceCard extends React.Component {
  static propTypes = {
    card: PropTypes.shape({
      buildings_type: PropTypes.array.isRequired,
    }),
  };

  /**
   * Метод жизненого цикла render().
   *
   * @returns {JSX.Element} - Реакт компонент.
   * @example ----
   */
  render() {
    const { props } = this;
    const {
      card,
      mode,
      required,
      editMode,
      isEditCurrentObjectIsExternalSystem,
    } = props;
    const { type_id: typeId } = card;
    const untenantable = 2;

    return (
      <CardContainer>
        <CardAbstract>
          <div id="editor-card">
            {printOghCardHeader(card, mode)}
            {card && objectTabs(typeId)}
            <div id="props">
              <FieldsSplitColumns>
                <SelectRF
                  name={'buildings_type_id'}
                  label={'Назначение'}
                  options={card.buildings_type}
                  disabled={!editMode}
                />
                <ParentInfo />
                <IsDiffHeightMarkField />
                <NoCalcRF disabled={!editMode} />
              </FieldsSplitColumns>
              {elementFormGrid(
                [
                  {
                    addition: {
                      address_required: true,
                      data: {
                        address: {
                          kl_kod: untenantable,
                        },
                      },
                      hintText: editMode ? getHint('near_address') : null,
                      noWrapper: true,
                      onlyOne: true,
                    },
                    component: renderAddressList2,
                    editable: editMode && isEditCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'address_list',
                    label: null,
                    name: 'address_list',
                  },
                ],
                1,
                'Адрес БТИ',
                typeId,
              )}
              <Tabs tabs={tabs} />

              <div id="characteristics">
                <FieldsSplitColumns>
                  <SelectRF
                    name={'material_id'}
                    label={'Материал'}
                    disabled={!editMode}
                    options={card.material}
                    required={required}
                  />
                  <AreaField />
                  <TotalAreaGeoRF disabled={true} decimalScale={2} />
                </FieldsSplitColumns>
              </div>

              <div hidden={true} id={'docs'}>
                <OtherDocumentsRF />
              </div>
            </div>
            <div hidden={true} id="versions">
              <Versions
                card={card}
                disabled={props.createMode}
                key={`versions-${card.root_id}`}
              />
            </div>
          </div>
        </CardAbstract>
      </CardContainer>
    );
  }
}

const getAbutmentTypes = getDict('abutmentTypes');

const techPlaceDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

/**
 * Функция получения и установки изначальных значений из State.
 *
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {object} - Поля.
 * @example ----
 */
const mapStateToProps = (state, props) => {
  const parse = getParseAttribute(props);
  const result = {
    abutmentTypes: getAbutmentTypes(state),
    createMode: isCreateMode(state, props),
    editMode: isEditMode(state, props),
    enableReinitialize: true,
    formValues: getFormValues(state),
    initialValues: {
      abutment_type_list: {
        showDialog: false,
        table: parse('abutment_type_list'),
      },
      address_list:
        (props.card.address_list &&
          props.card.address_list.map((item) => {
            return {
              ...item,
              ...item.attributes,
              attributes: null,
            };
          })) ||
        null,

      area: parse('area'),
      buildings_type_id: getInitialBuildingsTypeId(props, parse),
      endDate: parseDate(props.card.end_date),
      is_diff_height_mark: parse('is_diff_height_mark'),
      material_id: parse('material_id.material'),
      no_calc: parse('no_calc'),
      parent_name: props.card.parent_info,
      startDate: parseDate(props.card.start_date),
      totalAreaGeo: parse('total_area_geo'),
    },

    onSubmit: onSubmit.bind({ props }),
    required: isRequired(state, props),
    validate,
  };

  return techPlaceDocumentsReducer(result, state, props);
};

/**
 * Функция валидации.
 *
 * @param {object} values - Значения формы.
 * @returns {object} - Отвалидированные поля.
 * @example ----
 */
function validate(values) {
  return validateNumeric(values, numericFields);
}

/**
 * Функция создания атрибутов.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {object} - Возвращают атрибуты.
 * @example ----
 */
function createAttribute(formValues) {
  return {
    ...createDocumentsAttribute(formValues),
  };
}

const numericFields = [
  {
    name: 'area',
    positive: true,
    type: 'decimal',
  },
];

const dictFields = ['material_id', 'abutment_type_id', 'buildings_type_id'];

const commonMandatory = ['material_id', 'area'];

const mandatoryFields = {
  [TECH_PLACE]: ['buildings_type_id'].concat(commonMandatory),
};

/**
 * Функция сабмита.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {void} - Жопа.
 * @example ----
 */
export function onSubmit(formValues) {
  const values = { ...formValues };

  delete values.parent_name;

  const formValuesWithAttribute = {
    ...values,
    ...createAttribute(values),
  };

  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    mandatoryFields[this.props.card.type_id],
  );

  // if (_.get(formValuesWithAttribute, 'abutment_type_list.table[0]')) {
  //   formValuesWithAttribute.abutment_type_list =
  //     formValuesWithAttribute.abutment_type_list.table;
  // } else {
  //   delete formValuesWithAttribute.abutment_type_list;
  // }

  if (formValuesWithAttribute?.abutment_type_list) {
    delete formValuesWithAttribute.abutment_type_list;
  }

  submitCard(
    this.props,
    transformValues(formValuesWithAttribute, {
      custom: {
        address_list: {
          //

          /**
           * Метод transformer.
           *
           * @param {object} value - Значения.
           * @returns {object}
           */
          transformer: (value) => {
            return value
              ? value.map((item) => {
                  return _.mapValues(
                    _.pick(item, [
                      'okrug_id',
                      'address_id',
                      'street_id',
                      'district_id',
                      'description',
                    ]),
                    (id) => {
                      return { bti_object: id };
                    },
                  );
                })
              : null;
          },
        },
      },
      dictFields,
      numericFields,
    }),
  );
}

export default connect(mapStateToProps)(
  reduxForm({ form: 'editorCard' })(TechPlaceCard),
);
