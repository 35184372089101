import {
  validateCleanArea,
  validateNumeric,
} from 'app/components/card/common/validation';
import { numericFields } from 'app/components/card/ogh/PlanarStructureCard/numerlicFields';
import { validate } from 'app/components/card/ogh/PlanarStructureCard/validate';
import { RANDOM_WORD } from 'core/forTesting/constants';
import { vi } from 'vitest';

vi.mock('app/components/card/common/validation');

describe('🐛 ValidateSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    validateNumeric.mockReturnValue({ foo: RANDOM_WORD });
    validateCleanArea.mockReturnValue({ fe: RANDOM_WORD });

    // 🔥 Act

    const res = validate({});

    // ❓ Assert
    expect(res).toStrictEqual({ fe: RANDOM_WORD, foo: RANDOM_WORD });
  });
  it('🧪 called params validateNumeric', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act

    validate({ foo: RANDOM_WORD });

    // ❓ Assert
    expect(validateNumeric).toHaveBeenCalledWith(
      { foo: RANDOM_WORD },
      numericFields,
    );
  });
  it('🧪 called params validateCleanArea', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act

    validate({ foo: RANDOM_WORD });

    // ❓ Assert
    expect(validateCleanArea).toHaveBeenCalledWith({ foo: RANDOM_WORD });
  });
});
