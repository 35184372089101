import { formatResponseToGeoJSON } from 'app/actions/geometryActions/fetchCheckGeometryIntersections.DTW';
import {
  RANDOM_ID,
  RANDOM_NUMBER,
  RANDOM_WORD,
} from 'core/forTesting/constants';
import { useMapglEditorContext } from 'core/uiKit/components/DTW/contexts/MapglEditorContextProvider';
import { formatGeometriesToUpdate } from 'core/uiKit/components/DTW/contexts/utils';
import { useGetParamsUrl } from 'core/utils/hooks/useGetParamsUrl';
import { vi } from 'vitest';

import { useFetchActionWithGeometryDTW } from './useFetchActionWithGeometryDTW';
import { useNavigateWithLoading } from './useNavigateWithLoading';

vi.useFakeTimers();

vi.mock('./useNavigateWithLoading');
vi.mock('app/actions/geometryActions/fetchCheckGeometryIntersections.DTW');
vi.mock('core/uiKit/components/DTW/contexts/utils');
vi.mock('core/utils/hooks/useGetParamsUrl');
vi.mock('core/uiKit/components/DTW/contexts/MapglEditorContextProvider');
vi.mock('app/actions/geometryActions/fetchCheckGeometryIntersections.DTW');

describe('🐛 UseFetchActionWithGeometryDtwSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    useMapglEditorContext.mockReturnValue({});
    useNavigateWithLoading.mockReturnValue({});
    useGetParamsUrl.mockReturnValue({});

    // 🔥 Act
    const res = useFetchActionWithGeometryDTW();

    // ❓ Assert
    expect(res).toStrictEqual({
      fetchActionWithGeometryDTW: expect.any(Function),
    });
  });

  it('🧪 fetchActionWithGeometryDTW did not have id', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    const finishEditingSpy = vi.fn();
    const mapServiceSpy = vi.fn();
    const updateLoadedGeometriesSpy = vi.fn();
    const drawGeometriesSpy = vi.fn();

    useMapglEditorContext.mockReturnValue({
      drawGeometries: drawGeometriesSpy,
      finishEditing: finishEditingSpy,
      mapService: mapServiceSpy,
      updateLoadedGeometries: updateLoadedGeometriesSpy,
    });

    const navigateWithLoadingSpy = vi.fn();
    useNavigateWithLoading.mockReturnValue({
      navigateWithLoading: navigateWithLoadingSpy,
    });
    useGetParamsUrl.mockReturnValue({
      childId: undefined,
      parentId: undefined,
    });

    // 🔥 Act
    const { fetchActionWithGeometryDTW } = useFetchActionWithGeometryDTW();
    const res = fetchActionWithGeometryDTW(null);

    // ❓ Assert
    expect(res).toBe(undefined);
  });

  it('🧪 fetchActionWithGeometryDTW have id and childId case', async () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    const props = {
      fetchObjectsGeometry: vi.fn(),
      fetchTreeSelectedElement: vi.fn(),
      viewCard: vi.fn(),
    };
    props.fetchObjectsGeometry.mockResolvedValue(RANDOM_WORD);

    formatResponseToGeoJSON.mockReturnValue([{ pointer: RANDOM_WORD }]);
    formatGeometriesToUpdate.mockReturnValue('pointer');

    const finishEditingSpy = vi.fn();
    const mapServiceSpy = vi.fn();
    const updateLoadedGeometriesSpy = vi.fn(() => 'updated');
    const drawGeometriesSpy = vi.fn();

    useMapglEditorContext.mockReturnValue({
      drawGeometries: drawGeometriesSpy,
      finishEditing: finishEditingSpy,
      mapService: mapServiceSpy,
      updateLoadedGeometries: updateLoadedGeometriesSpy,
    });

    const navigateWithLoadingSpy = vi.fn();
    useNavigateWithLoading.mockReturnValue({
      navigateWithLoading: navigateWithLoadingSpy,
    });
    useGetParamsUrl.mockReturnValue({
      childId: RANDOM_NUMBER,
      parentId: RANDOM_ID,
    });

    // 🔥 Act
    const { fetchActionWithGeometryDTW } = useFetchActionWithGeometryDTW(props);
    fetchActionWithGeometryDTW(123);
    await vi.runOnlyPendingTimersAsync();

    // ❓ Assert
    expect(navigateWithLoadingSpy).toHaveBeenCalledWith(
      `/ogh/${RANDOM_ID}/${123}`,
    );
    expect(props.fetchObjectsGeometry).toHaveBeenCalledWith({ ids: [123] });
    expect(finishEditingSpy).toHaveBeenCalledWith(false);
    expect(formatResponseToGeoJSON).toHaveBeenCalledWith(RANDOM_WORD);
    expect(formatGeometriesToUpdate).toHaveBeenCalledWith(
      { pointer: RANDOM_WORD },
      'children',
      mapServiceSpy,
    );
    expect(updateLoadedGeometriesSpy).toHaveBeenCalledWith({
      children: 'pointer',
    });
    expect(drawGeometriesSpy).toHaveBeenCalledWith('updated');
    expect(props.viewCard).toHaveBeenCalledTimes(1);
  });

  it('🧪 fetchActionWithGeometryDTW have id case', async () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    const props = {
      fetchRootCard: vi.fn(),
    };
    props.fetchRootCard.mockResolvedValue({ isSuccess: true });

    const finishEditingSpy = vi.fn();
    useMapglEditorContext.mockReturnValue({
      finishEditing: finishEditingSpy,
    });

    const navigateWithLoadingSpy = vi.fn();
    useNavigateWithLoading.mockReturnValue({
      navigateWithLoading: navigateWithLoadingSpy,
    });
    useGetParamsUrl.mockReturnValue({
      childId: undefined,
      parentId: RANDOM_ID,
    });

    // 🔥 Act
    const { fetchActionWithGeometryDTW } = useFetchActionWithGeometryDTW(props);
    fetchActionWithGeometryDTW(123);
    await vi.runOnlyPendingTimersAsync();

    // ❓ Assert
    expect(props.fetchRootCard).toHaveBeenCalledWith(123);
    expect(finishEditingSpy).toHaveBeenCalledTimes(1);
    expect(navigateWithLoadingSpy).toHaveBeenCalledWith(`/ogh/${123}`);
  });
});
