import { getPlantServiceRecomendationsIdRequired } from 'app/components/card/ogh/TreesShrubs/fields/PlantServiceRecomendationsIdRF.TreesShrubs/getPlantServiceRecomendationsIdRequired';
import { STATE_GARDENING_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/StateGardeningId/index';

describe('GetPlantServiceRecomendationsIdRequired', function () {
  it('test values undefined', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const values = undefined;

    // Act

    // Assert
    expect(getPlantServiceRecomendationsIdRequired(values)).toBeFalsy();
  });
  it('test values 2', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const values = { [STATE_GARDENING_ID_NAME]: 2 };

    // Act

    // Assert
    expect(getPlantServiceRecomendationsIdRequired(values)).toBeTruthy();
  });
  it('test values {}', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const values = {};

    // Act

    // Assert
    expect(getPlantServiceRecomendationsIdRequired(values)).toBeFalsy();
  });
});
