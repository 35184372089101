// import { WithMapContext } from 'app/components/map/withMap';
import { clearCurrentCardCash } from 'app/api/hooks/useGetCurrentCard/clearCurrentCardCash';
import { prepareActionsTree } from 'app/components/card/ogh/index/card/utils/prepareActionsTree';
import { INTERSECTIONS } from 'app/constants/layers';
import { onSave } from 'app/utils/card/checkGeometry';
import {
  RANDOM_ID,
  RANDOM_NUMBER,
  RANDOM_WORD,
} from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import React from 'react';
import { vi } from 'vitest';

import LeftPanel from '../LeftPanel';
import IndexCard from './IndexCard';

vi.mock('app/utils/card/checkGeometry');
vi.mock('app/api/hooks/useGetCurrentCard/clearCurrentCardCash');
vi.mock('app/components/card/ogh/index/card/utils/prepareActionsTree');
vi.mock('config/env', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    SERVIS_MAP: 'egip',
  };
});

vi.useFakeTimers();

describe('🐛 spec IndexCard', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    const props = {
      Map: vi.fn(),
      card: {
        type_id: RANDOM_ID,
      },
      location: { pathname: '' },
    };

    // 🧹 clear mock

    //🔥 Act
    const wrapper = shallow(<IndexCard {...props} />);

    //❓ Assert
    expect(wrapper.text()).toBe(
      '<LeftPanelDefault /><RightPanel /><DialogAlert /><DialogAlert /><DialogAction />',
    );
  });
  it('🧪 onSave egip fetchRootCard', async () => {
    expect.hasAssertions();

    //☣️ Arrange (всякие моки)
    // TODO: Не нашел способа как это реализовать по другому, заменить на норм подход
    vi.clearAllMocks();
    vi.mock('config/env', async (importOriginal) => {
      const mod = await importOriginal();
      return {
        ...mod,
        SERVIS_MAP: 'egip',
      };
    });

    const sendUpdateCardSpy = vi.fn().mockResolvedValue(RANDOM_ID);
    const fetchRootCardSpy = vi.fn();
    const updateTreeSpy = vi.fn();
    const clearLayerSpy = vi.fn();
    const getGeometrySpy = vi.fn();
    const navigateWithLoadingSpy = vi.fn();
    const props = {
      Map: vi.fn(),
      actionsTree: RANDOM_WORD,
      allowedWithoutGeometry: true,
      card: {
        record_id: RANDOM_ID,
        type_id: RANDOM_NUMBER,
      },
      fetchActionWithGeometryDTW: vi.fn(),
      fetchRootCard: fetchRootCardSpy,
      hasGeometry: true,
      location: { pathname: '' },
      mapContext: {
        clearLayer: clearLayerSpy,
        getGeometries: vi.fn(),
        getGeometry: getGeometrySpy,
        getRawGeometry: vi.fn(),
      },
      navigateWithLoading: navigateWithLoadingSpy,
      parentInfo: {},
      sendUpdateCard: sendUpdateCardSpy,
      treeJournal: { cnt_action: true },
      updateTree: updateTreeSpy,
    };

    onSave.mockImplementation(
      (data, props, draw, showIgnoreIntersectionAlert, saveObject) => {
        saveObject();
      },
    );
    prepareActionsTree.mockResolvedValue({});

    //🔥 Act
    const wrapper = shallow(<IndexCard {...props} />);
    const { updateObject } = wrapper.find(LeftPanel).props();
    updateObject({ foo: 123 });
    await vi.runOnlyPendingTimersAsync();

    //❓ Assert
    expect(clearLayerSpy).toHaveBeenCalledWith(INTERSECTIONS);
    expect(getGeometrySpy).toHaveBeenCalledTimes(1);
    expect(onSave).toHaveBeenCalledTimes(1);
    expect(fetchRootCardSpy).toHaveBeenCalledWith(RANDOM_ID);
    expect(navigateWithLoadingSpy).toHaveBeenCalledWith(`/ogh/${RANDOM_ID}`);
    expect(prepareActionsTree).toHaveBeenCalledWith(RANDOM_WORD);
    expect(clearCurrentCardCash).toHaveBeenCalledTimes(1);
    expect(updateTreeSpy).toHaveBeenCalledTimes(1);
  });
});
