import { shallow } from 'enzyme';

import SidewalksComponent from './SidewalksComponent';

describe('🐛 SidewalksComponentSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = {
      card: {
        flat_element_type: [],
      },
      editMode: true,
      isEditCurrentObjectIsExternalSystem: true,
    };

    // 🔥 Act

    const wrapper = shallow(<SidewalksComponent {...props} />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<CardContainer />');
  });
});
