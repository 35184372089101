import { RANDOM_BOOL } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import { MafTypeLevel1IdField } from './MafTypeLevel1.Field';
import { useSetupMafTypeLevel1IdField } from './useSetup.MafTypeLevel1.Field';

vi.mock('./useSetup.MafTypeLevel1.Field');

describe('🐛 MafTypeLevel1Spec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    useSetupMafTypeLevel1IdField.mockReturnValue({});

    // 🔥 Act
    const wrapper = shallow(<MafTypeLevel1IdField />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<MafTypeLevel1IdRF />');
  });

  it('🧪 props', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    const props = {
      disabled: RANDOM_BOOL,
    };
    const filter = vi.fn();
    useSetupMafTypeLevel1IdField.mockReturnValue({ filter });

    // 🔥 Act
    const wrapper = shallow(<MafTypeLevel1IdField {...props} />);

    // ❓ Assert
    expect(wrapper.props()).toStrictEqual({
      disabled: RANDOM_BOOL,
      filter,
    });
  });
});
