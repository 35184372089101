import { shallow } from 'enzyme';

import { MafTypeLevel1RFWrapper } from './MafTypeLevel1RFWrapper';

describe('🐛 MafTypeLevel1RfWrapper', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act
    const wrapper = shallow(<MafTypeLevel1RFWrapper />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<MafTypeLevel1IdRF />');
  });

  it('🧪 filter', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act
    const wrapper = shallow(<MafTypeLevel1RFWrapper parentTypeId={1} />);
    const { filter } = wrapper.props();
    const expected = filter([
      {
        parent_ogh_object_type_list: [1, 2, 3],
      },
      {
        parent_ogh_object_type_list: [4, 5, 6],
      },
    ]);

    // ❓ Assert
    expect(expected).toStrictEqual([
      {
        parent_ogh_object_type_list: [1, 2, 3],
      },
    ]);
  });
});
